import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text-input/text-input.component';
import { ControlRegexDirective } from './directives/control-regex.directive';
import { GetFirstPipe } from './pipes/get-first.pipe';
import { MaterialModule } from '../../shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NumberInputComponent } from './number-input/number-input.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { ZipCodeInputComponent } from './zip-code-input/zip-code-input.component';
import { ZipCodeAutocompleteComponent } from './zip-code-autocomplete/zip-code-autocomplete.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FileInputComponent } from './file-input/file-input.component';

const FORM_FIELDS: Type<unknown>[] = [
  TextInputComponent,
  NumberInputComponent,
  AddressInputComponent,
  ZipCodeInputComponent,
  ZipCodeAutocompleteComponent,
  AutocompleteComponent,
  FileInputComponent
];
const DIRECTIVES: Type<unknown>[] = [ControlRegexDirective];
const PIPES: Type<unknown>[] = [GetFirstPipe];

@NgModule({
  declarations: [...FORM_FIELDS, ...DIRECTIVES, ...PIPES],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [...FORM_FIELDS, ...DIRECTIVES, ...PIPES]
})
export class CustomMatFormFieldsModule {}
