<ecmo-page ecmo-contacts [greyBackground]='true' [pageTitle]="'profile.title' | translate">
  <div class='container' *ngIf='user$ | async as user'>
    <div class='header'>
      <span class='title' *ngIf='user.firstName'>{{user.firstName}} {{user.lastName}}</span>
      <span class='title' *ngIf='!user.firstName'>{{user.societyName}} - SIREN :
        {{user.societyRegistrationNumber}}</span>
    </div>
    <ecmo-profile-card-edit [title]="'profile.cards.phone.title' | translate"
                            [editIcon]='false'
                            [content]="user.coordinates.phoneNumber"></ecmo-profile-card-edit>
    <ecmo-profile-card-edit [title]="'profile.cards.mail.title' | translate"
                            [editIcon]='false'
                            [content]="user.coordinates.emailAddress"></ecmo-profile-card-edit>
    <ecmo-profile-card-edit [title]="'profile.cards.password.title' | translate"
                            [url]="['https://iamclient-pingfedfr.staging.bnpparibas-pf.com/ext/pwdchange/Identify?AdapterId=ecmPartnerAwareHTMLForm&partnerId=' + (partner$ | async) ]"
                            [content]="'••••••••••'"></ecmo-profile-card-edit>
    <div class='modifications'><span>* {{ 'profile.modification' | translate }}</span></div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
