import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Address, toUser, User } from '../user.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToForm } from '../../../custom-mat-form-fields/form-fields.model';
import { map, Observable } from 'rxjs';
import { UserService } from '../user.service';

export type AddressForFront = Address & {
  supportingDocument: File | null;
};

@Component({
  selector: 'ecmo-address',
  templateUrl: './address.component.html',
  styleUrls: ['../email/email.component.scss', './address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponent {
  user$: Observable<User> = this.userService.getAll().pipe(map(toUser));
  addressForm = new FormGroup<ToForm<AddressForFront>>({
    streetName: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    complement: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    postCode: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    city: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    supportingDocument: new FormControl<File | null>(null, { nonNullable: true, validators: Validators.required })
  });

  constructor(private userService: UserService) {}

  submit(): void {
    if (this.addressForm.valid) {
      console.log(this.addressForm);
    }
  }
}
