import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class TranslationInitPreload {
  constructor(private readonly translateService: TranslateService) {}

  init(): Observable<void> {
    return this.translateService.use(this.translateService.defaultLang);
  }
}

export const loadTranslations = (translationInitPreload: TranslationInitPreload) => (): Observable<void> =>
  translationInitPreload.init();
