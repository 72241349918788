import { Injectable } from '@angular/core';
import { CacheableService } from '../../helpers/cacheable.service';
import { AppConfig } from '../../../app.config';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { urlJoin } from '../../helpers/url.helper';
import { ContactDTO } from './contacts.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsService extends CacheableService<ContactDTO[]> {
  constructor(appConfig: AppConfig, protected httpClient: HttpClient) {
    super(httpClient, of(urlJoin(appConfig.baseWsURL(), `api/partners/contacts`)));
  }
}
