<div class='container'>
  <div class='column'>
    <div class='row column no-gap'>
      <p [innerHTML]="'legals.cookies.0-1' | translate"></p>
      <p [innerHTML]="'legals.cookies.0-2' | translate"></p>

      <h2>{{ 'legals.cookies.title-1' | translate }}</h2>
      <p [innerHTML]="'legals.cookies.1-1' | translate"></p>
      <p [innerHTML]="'legals.cookies.listing-1.title' | translate"></p>
      <ul>
        <li [innerHTML]="'legals.cookies.listing-1.1' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-1.2' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-1.3' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-1.4' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-1.5' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-1.6' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-1.7' | translate"></li>
      </ul>

      <h2>{{ 'legals.cookies.title-2' | translate }}</h2>
      <p [innerHTML]="'legals.cookies.listing-2.title' | translate"></p>
      <ul>
        <li [innerHTML]="'legals.cookies.listing-2.1' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-2.2' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-2.3' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-2.4' | translate"></li>
        <li [innerHTML]="'legals.cookies.listing-2.5' | translate"></li>
      </ul>
      <p [innerHTML]="'legals.cookies.2-1' | translate"></p>

      <h2>{{ 'legals.cookies.title-3' | translate }}</h2>
      <p [innerHTML]="'legals.cookies.3-1' | translate"></p>
      <p [innerHTML]="'legals.cookies.3-2' | translate"></p>

      <h2>{{ 'legals.cookies.title-4' | translate }}</h2>
      <p [innerHTML]="'legals.cookies.4-1' | translate"></p>

      <h2>{{ 'legals.cookies.title-5' | translate }}</h2>
      <p [innerHTML]="'legals.cookies.5-1' | translate"></p>

      <h2>{{ 'legals.cookies.title-6' | translate }}</h2>
      <p [innerHTML]="'legals.cookies.6-1' | translate"></p>
      <mat-table [dataSource]='tableLines'>
        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef scope='col'> {{ 'legals.cookies.table.source' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.source}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef scope='col'> {{ 'legals.cookies.table.name' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef scope='col'> {{ 'legals.cookies.table.type' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="ttl">
          <th mat-header-cell *matHeaderCellDef scope='col'> {{ 'legals.cookies.table.ttl' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.ttl}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
      </mat-table>
    </div>
    <div class='row' *ngIf='withButton'>
      <button mat-stroked-button class='no-border'
              [routerLink]="['..']">{{ 'global.close' | translate }}</button>
    </div>
  </div>
</div>
