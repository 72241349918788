<ecmo-page [pageTitle]="'documents-request.amortization-schedule.title' | translate" [greyBackground]='true'>
  <div class='container'>
    <div class='column'>
      <div class='row column no-gap'>
        <p class='label' [innerHTML]="'documents-request.amortization-schedule.demand' | translate"></p>
        <p class='label' [innerHTML]="'documents-request.amortization-schedule.method' | translate"></p>
      </div>
      <div class='row'>
        <button mat-flat-button color='primary' [routerLink]="['../amortization-schedule-landing']"
                (click)='sendAmortizationScheduleDemand()'>{{ 'documents-request.amortization-schedule.confirm' |
          translate }}</button>
      </div>
      <div class='row'>
        <button mat-stroked-button class='no-border'
                [routerLink]="['..']">{{ 'global.cancel' | translate }}</button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
