/**
 * URL Utils
 */

export type URLParamValue = string | number | undefined;

/**
 * Join all urls path into one URL using '/'
 *
 * @param urls
 */
export const urlJoin = (...urls: string[]): string => urls.join('/');

/**
 * Join all map items into a UrlParams string
 *
 * @param map
 */
export const toPathParams = (map: Map<string, URLParamValue>): string =>
  [...map.entries()]
    .filter(([_, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

/**
 * Build a full url using url and params map
 *
 * @param url
 * @param map
 */
export const urlBuildWithParams = (url: string, map: Map<string, URLParamValue> | undefined): string =>
  !map ? url : [url, toPathParams(map)].filter(item => !!item).join('?');
