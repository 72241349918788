import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DocumentType } from '../contracts/contract.model';
import { AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private httpClient: HttpClient, private appConfig: AppConfig) {}

  getAccessionAgreement(): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${this.appConfig.baseWsURL()}/api/documents/consent`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  sendAmortizationScheduleRequest(contractId: number | string): Observable<void> {
    return this.httpClient.post<void>(`${this.appConfig.baseWsURL()}/api/documents/request`, {
      contractId,
      documentType: DocumentType.AMORTIZATION_TABLE
    });
  }

  sendSituationLetterRequest(contractId: number | string): Observable<void> {
    return this.httpClient.post<void>(`${this.appConfig.baseWsURL()}/api/documents/request`, {
      contractId,
      documentType: DocumentType.OVERALL_SITUATION
    });
  }

  sendInvoiceRequest(contractId: number | string, invoiceDate?: Date, allInvoices?: boolean): Observable<void> {
    return this.httpClient.post<void>(`${this.appConfig.baseWsURL()}/api/documents/request`, {
      contractId,
      documentType: DocumentType.INVOICE,
      ...(allInvoices ? { allInvoices: true } : { date: invoiceDate })
    });
  }
}
