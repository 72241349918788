import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional, Self } from '@angular/core';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'ecmo-zip-code-autocomplete',
  templateUrl: './zip-code-autocomplete.component.html',
  styleUrls: ['./zip-code-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZipCodeAutocompleteComponent<T> extends AutocompleteComponent<T> implements AfterContentInit {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, defaultErrorStateMatcher, parentForm, parentFormGroup, cdr, 'ecmo-zip-code-autocomplete');
  }
}
