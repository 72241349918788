import { isDefined, someFieldIncludes } from './object.helper';
import { extractFieldValues } from './array.helper';
import { Filters, Page, RentalFeeFilter, WithMonthlyRentalFee } from './paginator.model';
import { combineLatest, map, Observable } from 'rxjs';

export class Paginator<D extends WithMonthlyRentalFee> {
  filterCategories: Page<D>['availableFilters'] = {};
  localPageSize = 0;

  constructor(
    public readonly pageSize: Observable<number>,
    public readonly data: Observable<D[]>,
    public readonly globalFilterFields: (keyof D)[],
    public readonly filterCategoryFields: (keyof D)[],
    public readonly rentalFeeFilters: RentalFeeFilter[] = []
  ) {}

  getPage(pageNumber: number, filter = '', activeFilters: Filters<D> = {}): Observable<Page<D>> {
    return combineLatest([this.data, this.pageSize]).pipe(
      // prepare
      map(([localData, pageSize]) => {
        this.localPageSize = pageSize;
        const filteredData = localData.filter(
          d => (!filter || someFieldIncludes(d, this.globalFilterFields, filter)) && this.applyFilters(d, activeFilters)
        );
        const maxPageNumber = this.getMaxPageNumber(filteredData);
        return {
          firstElement: (Math.min(pageNumber, maxPageNumber) - 1) * this.localPageSize,
          filteredData: filteredData,
          numberOfPages: maxPageNumber,
          filterCategories: Object.keys(this.filterCategories).length
            ? this.filterCategories
            : this.computeCategories(localData)
        };
      }),
      // format
      map(({ firstElement, filteredData, numberOfPages, filterCategories }) => ({
        content: filteredData.slice(firstElement, firstElement + this.localPageSize),
        numberOfPages: numberOfPages,
        pageNumber: pageNumber,
        availableFilters: filterCategories
      }))
    );
  }

  getMaxPageNumber(data: unknown[]): number {
    return Math.ceil(data.length / this.localPageSize);
  }

  private computeCategories(localData: D[]): Filters<D> {
    const filterCategoriesWithoutMonthlyRentalFee = this.filterCategoryFields.filter(f => f !== 'monthlyRentalFee');
    const filterCategoryMonthlyRentalFee = this.filterCategoryFields.filter(f => f === 'monthlyRentalFee');
    this.filterCategories = {
      ...extractFieldValues(localData, filterCategoriesWithoutMonthlyRentalFee),
      ...(filterCategoryMonthlyRentalFee.length
        ? {
            monthlyRentalFee: this.rentalFeeFilters
              .filter(f => localData.some(d => this.isInRange(d, f)))
              .map(f => f.labelKey)
          }
        : {})
    };
    return this.filterCategories;
  }

  private applyFilters(d: D, activeFilters: Filters<D>): boolean {
    const rentalFeeFilters = activeFilters.monthlyRentalFee
      ?.map(label => this.rentalFeeFilters.find(f => f.labelKey === label))
      .filter(isDefined) as RentalFeeFilter[] | undefined;
    return (
      (!rentalFeeFilters &&
        (Object.keys(activeFilters).length === 0 ||
          (Object.keys(activeFilters) as (keyof Filters<D>)[])
            .filter(key => key !== 'monthlyRentalFee')
            .some(key => {
              const filters = activeFilters[key];
              return filters?.length && filters.some(f => d[key] === f);
            }))) ||
      (!!rentalFeeFilters && rentalFeeFilters.some(f => this.isInRange(d, f)))
    );
  }

  private isInRange(d: D, f: RentalFeeFilter): boolean {
    return (
      (!!f.min || f.min === 0) && Number(d.monthlyRentalFee) >= f.min && (!f.max || Number(d.monthlyRentalFee) < f.max)
    );
  }
}
