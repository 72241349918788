import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ecmo-request-landing',
  templateUrl: './request-landing.component.html',
  styleUrls: ['./request-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestLandingComponent {
  @Input() pageTitleKey = '';
  @Input() delay = false;
  @Input() subTextKey = '';
  @Input() backUrl = [''];
}
