import { URLParamValue } from '../helpers/url.helper';

export type Theming = {
  themeId: number;
  primaryColor: string;
  secondaryColor: string;
  colorPalette: Palette;
};

export class ThemingQueryParams {
  chainCode: URLParamValue = '';
  unionCode: URLParamValue = '';
  groupCode: URLParamValue = '';
  societyCode: URLParamValue = '';
  pointOfSaleCode: URLParamValue = '';

  constructor(initializer: Partial<ThemingQueryParams> = {}) {
    Object.assign(this, initializer);
  }
  toParamsMap(): Map<string, URLParamValue> {
    return new Map<string, URLParamValue>([
      ['chainCode', this.chainCode || ''],
      ['unionCode', this.unionCode || ''],
      ['groupCode', this.groupCode || ''],
      ['societyCode', this.societyCode || ''],
      ['pointOfSaleCode', this.pointOfSaleCode || '']
    ]);
  }
}
export type ThemingQueryParamsType = ThemingQueryParams;

/* eslint-disable @typescript-eslint/naming-convention */
export type PaletteColors = Pick<
  Palette,
  50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'A100' | 'A200' | 'A400' | 'A700'
>;

export const NOT_COLORS_THEME_KEYS: (keyof Palette)[] = ['useDarkTextColor', 'darkTextColor', 'lightTextColor'];

export type Palette = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  A100: string;
  A200: string;
  A400: string;
  A700: string;
  useDarkTextColor: string[];
  darkTextColor: string;
  lightTextColor: string;
};

export const THEMES_CONFIG = new Map<string, ThemingQueryParams>([
  ['CETELEM', new ThemingQueryParams({ societyCode: '700' })],
  ['LOISIRSFINANCE', new ThemingQueryParams({ societyCode: '785' })],
  ['VOLVO', new ThemingQueryParams({ unionCode: '7009314717' })],
  ['HONDA', new ThemingQueryParams({ unionCode: '7009316936' })],
  ['SUZUKI', new ThemingQueryParams({ unionCode: '7009315227' })],
  ['JAGUAR', new ThemingQueryParams({ unionCode: '7009317181' })],
  ['LANDROVER', new ThemingQueryParams({ unionCode: '7009317199' })],
  ['MAZDA', new ThemingQueryParams({ unionCode: '7009317223' })],
  ['RELACIO', new ThemingQueryParams({ chainCode: '7009110198' })],
  ['BPM', new ThemingQueryParams({ chainCode: '7209129925' })],
  ['QARSON', new ThemingQueryParams({ chainCode: '7009129933' })]
]);
