<ecmo-page [greyBackground]='true' [routerLink]='backUrl' [pageTitle]='pageTitleKey | translate'>
  <div class='container'>
    <div class='column' (click)='$event.stopImmediatePropagation()'>
      <div class='row center'>
        <mat-icon svgIcon='ecmo-sent' color='primary'></mat-icon>
      </div>
      <div class='row text'>
        <span>{{ 'request-landing.acknowledgement' | translate }}</span>
        <span *ngIf='delay; else noDelay'> {{ 'request-landing.delay' | translate }}</span>
        <ng-template #noDelay>.</ng-template>
      </div>
      <div class='row' *ngIf='subTextKey'>
        <span>{{ subTextKey | translate }}</span>
      </div>
      <div class='row'>
        <button mat-stroked-button class='no-border'
                [routerLink]='backUrl'>{{ 'global.close' | translate }}</button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
