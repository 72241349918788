import { Injectable } from '@angular/core';
import { EventType, ResolveEnd, Router } from '@angular/router';
import { filter, map, shareReplay, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  historySize = 20;
  routingUrlHistory: string[] = ['/'];
  activeUrlId = 0;
  backRoutingUrl$ = this.router.events.pipe(
    filter(e => e.type === EventType.ResolveEnd),
    map(e => e as ResolveEnd),
    tap(e => (this.routingUrlHistory[++this.activeUrlId] = e.url)),
    map(() => {
      this.routingUrlHistory = this.routingUrlHistory.slice(
        Math.max(0, this.activeUrlId - this.historySize),
        this.activeUrlId + 1
      );
      if (this.activeUrlId - this.historySize > 0) {
        this.activeUrlId--;
      }
      return this.routingUrlHistory[Math.max(this.activeUrlId - 1, 0)];
    }),
    shareReplay(1)
  );

  constructor(private router: Router) {}

  backButtonUsed(): void {
    this.activeUrlId = Math.max(0, this.activeUrlId - 2);
  }
}
