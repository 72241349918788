<ecmo-page [greyBackground]='true' [pageTitle]="'modification-request.title' | translate">
  <div class='container'>
    <div class='column'>
      <div class='row'>
        <a [routerLink]="['collection-date']">
          {{ 'modification-request.collection-date.title' | translate }}
        </a>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
      <div class='row'>
        <a [routerLink]="['banking-info']">
          {{ 'modification-request.bank-details' | translate }}
        </a>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
