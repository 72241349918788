<ecmo-page [pageTitle]="'error.denied.title' | translate" [greyBackground]='true' [showConnectedIcons]='false'>
  <div class='container'>
    <div class='column'>
      <div class='row column no-gap'>
        <p [innerHTML]="'error.denied.text' | translate"></p>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
