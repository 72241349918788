import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './contracts/summary/summary.component';
import { LayoutModule } from '../layout/layout.module';
import { MaterialModule } from '../../shared/material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactsComponent } from './contacts/contacts.component';
import { ProfileComponent } from './profile/profile.component';
import { ContractDetailComponent } from './contracts/contract-detail/contract-detail.component';
import { ModificationRequestComponent } from './modification-request/modification-request.component';
import { EmailComponent } from './profile/email/email.component';
import { RequestLandingComponent } from './request-landing/request-landing.component';
import { CollectionDateComponent } from './modification-request/collection-date/collection-date.component';
import { AddressComponent } from './profile/address/address.component';
import { BankingInfoComponent } from './modification-request/banking-info/banking-info.component';
import { DocumentsRequestComponent } from './documents-request/documents-request.component';
import { AmortizationScheduleComponent } from './documents-request/amortization-schedule/amortization-schedule.component';
import { InvoicesComponent } from './documents-request/invoices/invoices.component';
import { SituationLetterComponent } from './documents-request/situation-letter/situation-letter.component';
import { CookiesComponent } from './legals/cookies/cookies.component';
import { LegalTermsComponent } from './legals/legal-terms/legal-terms.component';
import { LogoutComponent } from './logout/logout.component';
import { CookiesOnlyComponent } from './legals/cookies/cookies-only/cookies-only.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

@NgModule({
  declarations: [
    SummaryComponent,
    ContactsComponent,
    ProfileComponent,
    ContractDetailComponent,
    ModificationRequestComponent,
    EmailComponent,
    RequestLandingComponent,
    CollectionDateComponent,
    AddressComponent,
    BankingInfoComponent,
    DocumentsRequestComponent,
    AmortizationScheduleComponent,
    InvoicesComponent,
    SituationLetterComponent,
    CookiesComponent,
    LegalTermsComponent,
    LogoutComponent,
    CookiesOnlyComponent,
    AccessDeniedComponent
  ],
  imports: [CommonModule, LayoutModule, MaterialModule, SharedModule]
})
export class ViewsModule {}
