import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional, Self } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { AbstractControl, FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NUMBER_REGEX } from '../regex.model';
import { toValidator } from '../form-fields.model';

@Component({
  selector: 'ecmo-zip-code-input',
  templateUrl: './zip-code-input.component.html',
  styleUrls: ['../text-input/text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZipCodeInputComponent extends TextInputComponent implements AfterContentInit {
  controlRegex = NUMBER_REGEX;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, defaultErrorStateMatcher, parentForm, parentFormGroup, cdr, 'ecmo-zip-code-input');
  }

  ngAfterContentInit(): void {
    this.initControl();
    if (this.control) {
      this.control.addValidators([
        Validators.maxLength(5),
        toValidator(
          (control: AbstractControl) =>
            !control.value ||
            typeof control.value === 'object' ||
            (NUMBER_REGEX.test(control.value) && control.value.length === 5),
          'global.zipCodeLength'
        )
      ]);
    }
  }
}
