<ecmo-page [pageTitle]="'legals.legal-terms.title' | translate" [greyBackground]='true'>
  <div class='container'>
    <div class='column'>
      <div class='row column no-gap'>
        <p class='label' *ngIf='legalTerms$ | async as legalTerms' [innerHTML]='legalTerms'></p>
      </div>
      <div class='row'>
        <button mat-stroked-button class='no-border'
                [routerLink]="['..']">{{ 'global.close' | translate }}</button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
