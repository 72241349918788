import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ecmo-cookies-only',
  templateUrl: './cookies-only.component.html',
  styleUrls: [
    '../../../documents-request/amortization-schedule/amortization-schedule.component.scss',
    './cookies-only.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesOnlyComponent {
  @HostBinding('class.embedded')
  @Input()
  withButton = false;

  tableLines = [
    {
      source: 'fr-fed-iamclient.bnpparibas-pf.com',
      name: 'TS0173894d, PF, BIGipServer~PERSONAL_FINANCE~AP24205_iampart_pingfed_sta_http_tcp_443~P_iamclient_pingfedfr__http_443, pf-hfa-ecmPartnerAwareHTMLForm-rmu',
      type: 'Données identification',
      ttl: 'Session, Session, Session, 1 mois'
    },
    {
      source: 'monespace-mobilite.fr',
      name: '0-ecm , ecm-partner-name, ecmo-partner, ecmo-theme',
      type: 'Données identification, Personnalisation, Personnalisation, Personnalisation',
      ttl: 'Session navigateur, Session, Session, Session'
    }
  ];
  columns = ['source', 'name', 'type', 'ttl'];
}
