import { Directive, Input, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { skip, Subject, takeUntil, tap } from 'rxjs';
import { TakeUntil } from '../../helpers/take-until.component';
import { addErrors } from '../form-fields.model';

@Directive({
  selector: '[ecmoControlRegex]'
})
export class ControlRegexDirective extends TakeUntil implements OnInit {
  @Input() ecmoControlRegex!: string | RegExp;
  private localRegExp!: RegExp;
  private valueBeforeChange!: string;
  private showHelp = new Subject<string>();
  private letters: string[] = [];
  constructor(@Optional() private ngControl: NgControl) {
    super();
  }

  ngOnInit(): void {
    if (!this.ecmoControlRegex) {
      throw new Error("You must set a value for 'ecmoControlRegex' directive");
    }
    if (!this.ngControl) {
      throw new Error("You must set a formControl for 'ecmoControlRegex' directive");
    }

    this.localRegExp =
      typeof this.ecmoControlRegex === 'string'
        ? new RegExp(this.ecmoControlRegex.replace(/\//g, ''))
        : this.ecmoControlRegex;

    this.ngControl.control?.valueChanges.pipe(takeUntil(this.destroy)).subscribe(value => {
      if (typeof value === 'object') {
        return;
      }
      if ((value === 0 || value) && !this.localRegExp.test(value)) {
        this.ngControl.control?.setValue(this.valueBeforeChange, { emitEvent: false });
        this.showHelp.next(value[value.length - 1]);
      } else {
        this.valueBeforeChange = value;
      }
    });

    this.showHelp
      .pipe(
        takeUntil(this.destroy),
        tap(letter => {
          if (!this.letters.includes(letter)) {
            this.letters.push(letter);
            if (this.letters.length > 5) {
              this.letters.shift();
            }
          }
        }),
        skip(5)
      )
      .subscribe(() => {
        if (this.ngControl.control) {
          addErrors(this.ngControl.control, { invalidCharacters: { values: [...this.letters] } });
          this.ngControl.control.markAsTouched();
        }
      });
  }
}
