import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToForm, toValidator } from '../../../custom-mat-form-fields/form-fields.model';
import { CollectionDate } from './collection-date.model';
import { map, Observable, tap } from 'rxjs';
import { ContractType } from '../../contracts/contract.model';
import { ContractService } from '../../contracts/contract.service';

@Component({
  selector: 'ecmo-collection-date',
  templateUrl: './collection-date.component.html',
  styleUrls: ['./collection-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionDateComponent {
  contractType$!: Observable<ContractType>;
  _contractId!: number | string;
  actualDay!: number;
  min = 5;
  max = 20;

  collectionDateForm = new FormGroup<ToForm<CollectionDate>>({
    collectionDate: new FormControl<number>(this.actualDay, {
      nonNullable: true,
      validators: [
        Validators.required,
        toValidator(control => control.value >= this.min && control.value <= this.max, 'collection-date.between'),
        toValidator(control => control.value !== this.actualDay, 'collection-date.change')
      ]
    })
  });

  @Input()
  set contractId(contractId: number | string) {
    this._contractId = contractId;
    this.contractType$ = this.contractService.getContract(contractId).pipe(
      tap(contract => (this.actualDay = Number(contract.collectionDay))),
      map(contract => contract.creditType),
      tap(contractType => {
        if (contractType === ContractType.LEASE) {
          this.collectionDateForm.get('collectionDate')?.setValue(this.actualDay === 5 ? 20 : 5);
          this.collectionDateForm.get('collectionDate')?.disable();
        } else {
          this.collectionDateForm.get('collectionDate')?.setValue(this.actualDay);
          this.collectionDateForm.enable();
        }
      })
    );
  }

  constructor(private contractService: ContractService) {}

  sendCollectionDate(): void {
    if (this.collectionDateForm.valid || this.isALeaseContractModification()) {
      this.contractService
        .changeCollectionDate(this._contractId, this.collectionDateForm.getRawValue().collectionDate)
        .subscribe();
    }
  }

  isALeaseContractModification(): boolean {
    return (
      this.collectionDateForm.disabled &&
      [this.actualDay, this.collectionDateForm.getRawValue().collectionDate].every(numDay =>
        [20, 5].some(authorized => Number(numDay) === authorized)
      )
    );
  }
}
