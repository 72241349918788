<ng-container *ngIf='control'>
  <div class='item'>
    <label [ngClass]='{
      "mat-mdc-form-field-error": control.invalid && control.touched,
      "required": required
    }' [for]="id">
      {{label}}
    </label>
    <span class='sub' *ngIf='format'>{{format}}</span>
  </div>
  <div class='item'>
    <mat-form-field file-input [hintLabel]='hint' (click)='fileInput.openFilePicker()'>
      <mat-icon matPrefix>add_circle_outline</mat-icon>
      <ngx-mat-file-input #fileInput [formControl]='control' [placeholder]='placeholder'>
        <mat-icon ngxMatFileInputIcon></mat-icon>
      </ngx-mat-file-input>
      <ng-content></ng-content>
      <mat-error *ngIf="control | getFirst as error">{{ error.key | translate: error.parameters }}</mat-error>
    </mat-form-field>
  </div>
</ng-container>
