import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ecmo-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: [
    '../documents-request/amortization-schedule/amortization-schedule.component.scss',
    './access-denied.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessDeniedComponent {}
