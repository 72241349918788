import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  Self
} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { TextInputComponent } from '../text-input/text-input.component';
import { NUMBER_REGEX } from '../regex.model';

@Component({
  selector: 'ecmo-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['../text-input/text-input.component.scss', './number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberInputComponent extends TextInputComponent implements AfterContentInit {
  @Input() min = 0;
  @Input() max!: number;
  NUMBER_REGEX = NUMBER_REGEX;
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, defaultErrorStateMatcher, parentForm, parentFormGroup, cdr, 'ecmo-number-input');
  }

  writeValue(val: string): void {
    const value = Number(val);
    if (!isNaN(value) && this.control?.value !== value) {
      this.control?.setValue(value, { emitEvent: false });
    }
  }

  ngAfterContentInit(): void {
    this.initControl();
    if (this.control) {
      this.subscriptions.push(
        this.control.valueChanges.subscribe(value => {
          const newValue = Number(value);
          if (!isNaN(newValue)) {
            this.control?.setValue(newValue, { emitEvent: false });
          }
        })
      );
    }
  }

  changeControlValue(toAdd: number): void {
    this.control?.setValue((this.control?.value || 0) + toAdd);
  }
}
