export const someFieldIncludes = <T, F extends keyof T>(object: T, fields: F[], search: string): boolean =>
  !!search.length &&
  fields
    .map(f => object[f])
    .join('◬')
    .toLowerCase()
    .includes(search.toLowerCase());

export const transformedIncludes = <T>(object: T, transform: (_: T) => string, search: string): boolean =>
  transform(object).toLowerCase().includes(search.toLowerCase());

export const isDefined = <T>(val: unknown): val is T => val !== undefined && val !== null;
