<ng-container *ngIf='control'>
  <div class='item'>
    <label [ngClass]='{
      "mat-mdc-form-field-error": control.invalid && control.touched,
      "required": required
    }' [for]="id">
      {{label}}
    </label>
  </div>
  <div class='item'>
    <mat-form-field [hintLabel]='hint'>
      <button mat-stroked-button [color]="'primary'" matPrefix
              [disabled]="control.disabled || !control.value || control.value <= min"
              (click)="changeControlValue(-1)">-</button>
      <input [type]='type' matInput [formControl]='control' [autocomplete]='autocomplete' [maxlength]="maxlength"
             [placeholder]="placeholder" [id]="id" [ecmoControlRegex]="NUMBER_REGEX"/>
      <button mat-stroked-button [color]="'primary'" matSuffix (click)="changeControlValue(+1)"
              [disabled]='control.disabled || control.value >= max'>+</button>
      <mat-error *ngIf="control | getFirst as error">{{ error.key | translate: error.parameters }}</mat-error>
    </mat-form-field>
  </div>
</ng-container>
