import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ecmo-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: [
    '../../documents-request/amortization-schedule/amortization-schedule.component.scss',
    './cookies.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesComponent {}
