export type UserDTO = {
  title: string;
  firstName: string;
  lastName: string;
  societyName: string;
  societyRegistrationNumber: string;
  coordinates: Coordinates;
  address: Address;
};

export type User = Pick<
  UserDTO,
  'firstName' | 'lastName' | 'coordinates' | 'address' | 'societyName' | 'societyRegistrationNumber'
> & {
  civility: string;
};

export type Coordinates = {
  emailAddress: string;
  phoneNumber: string;
};

export type Address = {
  streetName: string;
  complement?: string;
  city: string;
  postCode: string;
  country?: string;
};

export const toUser = (data: UserDTO): User => {
  const { title, ...user } = data;
  return { ...user, civility: title };
};
