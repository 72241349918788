import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BYPASS_AUTHENTICATION_ENABLED } from './auth-config.module';
import { of } from 'rxjs';

export const authGuard: CanActivateFn = () => {
  const oidcSecurityService = inject(OidcSecurityService);
  const bypassAuthentication = inject(BYPASS_AUTHENTICATION_ENABLED);
  return bypassAuthentication ? of(true) : oidcSecurityService.isAuthenticated();
};
