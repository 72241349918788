import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfig, Config, VERSION_TOKEN } from './app/app.config';
import packageJson from 'package-lock.json';
import { AUTH_CONFIG_TOKEN } from './app/auth/auth-config.module';
import { AUTH_INTERCEPTOR_CONFIG_TOKEN } from './app/auth/interceptor-config.model';

if (environment.production) {
  enableProdMode();
}

const loadConfig = (): Promise<void | Config> => {
  return new Promise<Config>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', './assets/config.json');

    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        resolve(JSON.parse(xhr.responseText.replace(/\n/g, '')));
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        reject();
      }
    });

    xhr.send(null);
  }).catch(err => console.error('error while parsing config', err));
};

const manageUserCreation = (config: Config, partner: string, username: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${config.url}/api/account/activate?partner=${partner}&username=${username}`);

    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        resolve();
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        reject();
      }
    });

    xhr.send(null);
  }).catch(err => console.error('error while parsing config', err));
};

loadConfig().then(config => {
  if (!config) {
    console.error('Config has not been loaded');
    return;
  }

  if (window.location.href.includes('/#/create-account?')) {
    const match = window.location.href.match(/partner=(?<partner>[^&]+)&username=(?<username>[^&]+)$/);
    if (match) {
      const { partner, username } = match.groups as { partner: string; username: string };
      manageUserCreation(config, partner, username).then(() => {
        window.location.replace(
          `${window.location.href.split('#')[0]}create-account.html?partnerId=${PARTNER_MAP.get(
            partner
          )}&partner=${partner}`
        );
      });
    } else {
      console.error('404 Resource not found');
    }
    return;
  }

  const partner = getPartnerFromUrl();

  platformBrowserDynamic([
    {
      provide: AppConfig,
      useValue: {
        baseWsURL: (): string => config.url,
        ssoUrl: (): string => config.ssoBaseUrl,
        cookieSecret: (): string => config.cookieSecret
      }
    },
    {
      provide: AUTH_CONFIG_TOKEN,
      useValue: { ...config.ssoConfig, partner }
    },
    {
      provide: AUTH_INTERCEPTOR_CONFIG_TOKEN,
      useValue: config.interceptor
    },
    {
      provide: VERSION_TOKEN,
      useValue: packageJson.version
    }
  ])
    .bootstrapModule(AppModule, { preserveWhitespaces: true })
    .catch(err => console.log(err));
});

const PARTNER_MAP = new Map<string, string>([
  ['cetelem', 'CETELEM'],
  ['loisirs-finance', 'LOISIRSFINANCE'],
  ['volvo', 'VOLVO'],
  ['honda', 'HONDA'],
  ['suzuki', 'SUZUKI'],
  ['jaguar', 'JAGUAR'],
  ['land-rover', 'LANDROVER'],
  ['mazda', 'MAZDA'],
  ['relacio', 'RELACIO'],
  ['bpm', 'BPM'],
  ['qarson', 'QARSON']
]);

const getPartnerFromUrl = ((): (() => string) => {
  function isAuthenticating(partnerFromUrl: string): boolean {
    return ['code=', 'state='].every(str => partnerFromUrl.includes(str));
  }

  function isAuthenticated(): boolean {
    const storage = localStorage.getItem('0-ecm');
    const accessToken = storage ? JSON.parse(storage)['access_token_expires_at'] : null;
    return !!storage && new Date(accessToken) > new Date();
  }

  function onCookiePage(): boolean {
    return window.location.href.endsWith('/#/privacy-cookies');
  }

  return (): string => {
    let partnerFromUrl = window.location.href
      .split('/')
      .filter(e => !!e)
      .pop() as string;
    const partner = PARTNER_MAP.get(partnerFromUrl);

    const wantedPartner = sessionStorage.getItem('ecm-wanted-partner-name');
    if (wantedPartner) {
      partnerFromUrl = wantedPartner;
      sessionStorage.removeItem('ecm-wanted-partner-name');
    }
    const storage = localStorage.getItem('0-ecm');
    if (storage) {
      const userData = JSON.parse(storage)['userData'];
      if (userData) {
        const loggedInPartner = userData['partner_name'];
        if (partner !== loggedInPartner) {
          if (partner && partner !== 'logout') {
            sessionStorage.setItem('ecm-wanted-partner-name', partnerFromUrl || partner || '');
            sessionStorage.setItem('ecm-wanted-sso-partner-name', partner || '');
            partnerFromUrl = 'logout';
          } else {
            partnerFromUrl = loggedInPartner;
          }
        }
      }
    }
    const isDenied = window.location.href.includes('error=access_denied');
    if (
      !(partner || wantedPartner || isAuthenticating(partnerFromUrl) || isAuthenticated() || onCookiePage() || isDenied)
    ) {
      throw new Error('URL not found');
    }
    if (partner) {
      sessionStorage.setItem('ecm-partner-theme-name', partner);
      sessionStorage.setItem('ecm-partner-name', partnerFromUrl);
    }
    if (wantedPartner) {
      sessionStorage.setItem('ecm-partner-name', wantedPartner);
    }
    if (isDenied) {
      sessionStorage.setItem('ecm-partner-name', 'denied');
    }
    return partner || '';
  };
})();
