import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DocumentService } from '../document.service';

@Component({
  selector: 'ecmo-amortization-schedule',
  templateUrl: './amortization-schedule.component.html',
  styleUrls: ['./amortization-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmortizationScheduleComponent {
  @Input() contractId!: number | string;

  constructor(private documentService: DocumentService) {}

  sendAmortizationScheduleDemand(): void {
    this.documentService.sendAmortizationScheduleRequest(this.contractId).subscribe();
  }
}
