import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'ecmo-logout',
  templateUrl: './logout.component.html',
  styleUrls: [
    '../documents-request/amortization-schedule/amortization-schedule.component.scss',
    './logout.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent {
  constructor(private appConfig: AppConfig, private oidcSecurityService: OidcSecurityService) {}
  logout(): void {
    this.oidcSecurityService.logoffLocalMultiple();
    sessionStorage.removeItem('ecmo-theme');
    sessionStorage.removeItem('ecmo-partner');
    localStorage.removeItem('0-ecm');
    window.open(this.appConfig.ssoUrl() + '/idp/startSLO.ping', '_blank');
    const partner = sessionStorage.getItem('ecm-wanted-sso-partner-name');
    if (partner) {
      this.oidcSecurityService.authorize(undefined, {
        customParams: {
          partnerId: partner
        }
      });
    }
  }
}
