const anyOf = (...regs: RegExp[]): RegExp => {
  const sources = regs.map(reg => (reg.source[0] === '[' ? reg.source : `[${reg.source}]`));
  return new RegExp(sources.join('').replace(/]\[/g, ''));
};
export const LOWERCASE = /[a-z]/;
export const UPPERCASE = /[A-Z]/;
export const NUMBER = /[0-9]/;
export const ALPHANUM = anyOf(UPPERCASE, NUMBER, LOWERCASE);
export const ADDRESS_SPECIAL_CHAR = /[' ]/;
export const ADDRESS_CHARS = anyOf(UPPERCASE, NUMBER, LOWERCASE, ADDRESS_SPECIAL_CHAR);

const DASH = /-/;
const MAIL_SPECIAL_CHAR = anyOf(DASH, /[!#$%&'*+/=?^_`{|}~]/);
export const EMAIL_REGEX = new RegExp(
  `^(${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}+(\\.${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}*)*)` +
    `@(${ALPHANUM.source}(${anyOf(ALPHANUM, DASH).source}*${ALPHANUM.source})*)` +
    `\\.(${ALPHANUM.source}${anyOf(ALPHANUM, DASH).source}{0,2}${ALPHANUM.source})$`
);
export const EMAIL_REGEX_INPUT = new RegExp(
  `^(${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}+(\\.${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}*)*)@?$` +
    `|` +
    `^(${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}+(\\.${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}*)*)` +
    `@(${ALPHANUM.source}(${anyOf(ALPHANUM, DASH).source}*${ALPHANUM.source})*)\\.?${ALPHANUM.source}?$` +
    `|` +
    `^(${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}+(\\.${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}*)*)` +
    `@(${ALPHANUM.source}(${anyOf(ALPHANUM, DASH).source}*${ALPHANUM.source})*)` +
    `\\.(${ALPHANUM.source}${anyOf(ALPHANUM, DASH).source}{0,2}${ALPHANUM.source})$`
);

export const NUMBER_REGEX = new RegExp(`^${NUMBER.source}*$`);
export const ADDRESS_REGEX = new RegExp(`^${ALPHANUM.source}${ADDRESS_CHARS.source}*$`);
