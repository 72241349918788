import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { Subject, Subscription } from 'rxjs';
import { AbstractMatFormField } from '../form-fields.model';
import { MatAutocomplete } from '@angular/material/autocomplete';

export const HTML_SELECTOR = new InjectionToken<string>('HTML_SELECTOR');

@Component({
  selector: 'ecmo-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputComponent
  extends mixinErrorState(AbstractMatFormField)
  implements ControlValueAccessor, AfterContentInit, DoCheck, OnDestroy
{
  static count = 0;
  @Input() label = '';
  @Input() type = 'text';
  @Input() autocomplete = 'off';
  @Input() placeholder = '';
  @Input() maxlength: number | null = null;
  @Input() matAutocomplete!: MatAutocomplete;
  @Input() toggleValidationIcon = false;
  @Input() hint = '';
  control: FormControl | null = null;
  id: string;

  onChange?: (value: string) => void;
  onTouched?: () => void;
  required = false;
  subscriptions: Subscription[] = [];

  controlBlurred = false;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    protected cdr: ChangeDetectorRef,
    @Optional() @Inject(HTML_SELECTOR) protected htmlSelector: string
  ) {
    super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl, new Subject<void>());
    this.id = (this.htmlSelector || 'fol-text-input') + ++TextInputComponent.count;
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    } else {
      throw new Error(`You must set a formControl for all '${this.htmlSelector || 'ecmo-text-input'}'`);
    }
  }

  @HostListener('focusout')
  setControlBlurred(): void {
    this.controlBlurred = true;
  }

  ngDoCheck(): void {
    this.updateErrorState();
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(val: string): void {
    if (this.control?.value !== val) {
      this.control?.setValue(val);
    }
  }

  initControl(): void {
    if (this.ngControl.control) {
      this.control = this.ngControl.control as FormControl;
      this.required = this.control.hasError('required');

      this.subscriptions.push(
        this.control.statusChanges.subscribe(() => {
          this.cdr.markForCheck();
        })
      );
    }
  }

  ngAfterContentInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
