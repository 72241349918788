import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ThemingService } from '../../theming/theming.service';
import { CacheService } from '../../cache.service';
import { mergeMap, of, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ContractService } from '../../views/contracts/contract.service';
import { IsMobileViewService } from '../../helpers/is-mobile-view.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'ecmo-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageComponent {
  @Input() pageTitle = '';
  @Input() showConnectedIcons = true;

  @HostBinding('class.grey-background')
  @Input()
  greyBackground = false;

  previousUrl$ = this.cacheService.backRoutingUrl$;
  logoUrl$ = this.themingService.getCurrentLogoUrl();
  onRootUrl$ = of(this.activatedRoute.snapshot.component?.name).pipe(
    mergeMap(componentName => {
      if (componentName === 'SummaryComponent') {
        return of(true);
      }
      if (componentName === 'ContractDetailComponent') {
        return this.contractService.hasOnlyOneContract();
      }
      return of(false);
    })
  );
  isMobileView$ = this.isMobileViewService.mobile$;

  constructor(
    private themingService: ThemingService,
    private cacheService: CacheService,
    private activatedRoute: ActivatedRoute,
    private contractService: ContractService,
    private isMobileViewService: IsMobileViewService,
    private oidcSecurityService: OidcSecurityService,
    protected appConfig: AppConfig
  ) {}

  backButtonClicked(): void {
    this.cacheService.backButtonUsed();
  }

  logout(): void {
    this.oidcSecurityService.userData$.pipe(take(1)).subscribe(data => {
      window.open(this.appConfig.ssoUrl() + '/idp/startSLO.ping', '_blank');

      this.oidcSecurityService.logoffLocalMultiple();
      sessionStorage.removeItem('ecmo-theme');
      sessionStorage.removeItem('ecmo-partner');
      setTimeout(
        () =>
          this.oidcSecurityService.authorize(undefined, {
            customParams: {
              partnerId: data.userData['partner_name']
            }
          }),
        100
      );
    });
  }
}
