<ecmo-page [pageTitle]="'modification-request.collection-date.title' | translate" [greyBackground]='true'>
  <div class='container' *ngIf='contractType$ | async as contractType'>
    <div class='column'>
      <div class='row actual'>
        <span class='label' [innerHTML]="'modification-request.collection-date.actual' | translate: { actual: actualDay }"></span>
      </div>
      <div class='row separator'></div>
      <div class='row' [formGroup]='collectionDateForm'>
        <ecmo-number-input formControlName='collectionDate'
                           class='no-disable'
                           [min]='min'
                           [max]='max'
                           [hint]="('modification-request.collection-date.hint' + (contractType === 'LEASE' ? 'Bail' :
                            'Conso') | translate)"
                           [label]="'modification-request.collection-date.label' | translate"
                           [toggleValidationIcon]="false"
                           autocomplete='off'/>
      </div>
      <div class='row'>
        <button mat-flat-button color='primary' [routerLink]="['../collection-date-landing']"
                (click)='sendCollectionDate()'
                [disabled]='collectionDateForm.invalid'>{{ 'global.send' | translate }}</button>
      </div>
      <div class='row'>
        <button mat-stroked-button class='no-border'
                [routerLink]="['/']">{{ 'global.cancel' | translate }}</button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
