import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional, Self } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { ADDRESS_REGEX } from '../regex.model';
import { toValidator } from '../form-fields.model';

@Component({
  selector: 'ecmo-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['../text-input/text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressInputComponent extends TextInputComponent implements AfterContentInit {
  controlRegex = ADDRESS_REGEX;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, defaultErrorStateMatcher, parentForm, parentFormGroup, cdr, 'ecmo-address-input');
  }

  ngAfterContentInit(): void {
    this.initControl();
    if (this.control) {
      this.control.addValidators([
        Validators.maxLength(32),
        toValidator(
          (control: AbstractControl) => !control.value || ADDRESS_REGEX.test(control.value),
          'personal-data.textPattern'
        )
      ]);
    }
  }
}
