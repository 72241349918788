<div class='panel-container'>
  <div class='header'>
    <span class='title'>{{ title }}</span>
  </div>
  <div class='content'>
    <div #contentWrapper>
      <ng-content></ng-content>
    </div>
    <div class='empty' *ngIf="!contentWrapper.childNodes || contentWrapper.childNodes.length <= 1">
      {{ messageIfEmpty }}
    </div>
  </div>
</div>
