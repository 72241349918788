import { Contract, ContractDetail, ContractDetailDTO, ContractDTO } from './contract.model';

export const toContract = ({
  brand,
  model,
  folderId,
  monthlyInstalmentAmount: monthlyRentalFee,
  plateNumber: registration
}: ContractDTO): Contract => ({
  brand,
  model,
  folderId,
  monthlyRentalFee,
  registration
});

export const toContractDetail = ({
  brand,
  model,
  folderId,
  monthlyInstalmentAmount,
  plateNumber,

  product,
  creditType,
  residualValueAmount,
  pointOfSale,
  nextPaymentDate,
  creditBeginningDate,
  creditEndingDate,
  insurances,
  documents,
  levyDayOfMonth,
  iban
}: ContractDetailDTO): ContractDetail => ({
  ...toContract({ brand, model, folderId, monthlyInstalmentAmount, plateNumber }),
  productType: product,
  creditType,
  residualValue: residualValueAmount,
  pointOfSale,
  nextPaymentDate: new Date(nextPaymentDate),
  creditBeginningDate: new Date(creditBeginningDate),
  creditEndingDate: new Date(creditEndingDate),
  numberOfMonths: monthDiff(new Date(creditBeginningDate), new Date(creditEndingDate)),
  remainingMonths: monthDiff(new Date(nextPaymentDate), new Date(creditEndingDate)) + 1,
  insurances,
  documents,
  collectionDay: levyDayOfMonth,
  iban
});

const monthDiff = (past: Date, future: Date): number => {
  let months = (future.getFullYear() - past.getFullYear()) * 12;
  months -= past.getMonth();
  months += future.getMonth();
  return months <= 0 ? 0 : months;
};
