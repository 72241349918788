<div class='footer'>
  <a [routerLink]="['/cookies']">Politique de cookies</a>
  <a [routerLink]="['/legal-terms']">Mentions légales</a>
  <a
    target='_blank'
    rel='noopener'
    href='https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20PF%20sans%20option%20clause%2008042022%20PF%2017052022'>
    Données personnelles
  </a>
</div>
