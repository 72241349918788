import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ContractService } from '../../contracts/contract.service';
import { ContactsService } from '../../contacts/contacts.service';
import { ContactType } from '../../contacts/contacts.model';

@Component({
  selector: 'ecmo-banking-info',
  templateUrl: './banking-info.component.html',
  styleUrls: ['./banking-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankingInfoComponent {
  bankingInfo$!: Observable<string>;
  savAddress$ = this.contactsService
    .getAll()
    .pipe(map(items => items.find(contract => contract.type === ContactType.SAV)?.postalAddress));
  @Input()
  set contractId(contractId: number | string) {
    this.bankingInfo$ = this.contractService.getContract(contractId).pipe(map(contract => contract.iban));
  }

  constructor(private contractService: ContractService, private contactsService: ContactsService) {}
}
