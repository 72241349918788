import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export type ErrorForTransform = {
  key: string;
  parameters: {
    [id: string]: string;
  };
};

@Pipe({
  name: 'getFirst',
  pure: false
})
export class GetFirstPipe implements PipeTransform {
  transform(control: AbstractControl | null): ErrorForTransform {
    if (!control || control.errors === null) {
      return { key: '', parameters: {} };
    } else {
      const keys = Object.keys(control.errors);
      let firstError = keys[0];

      if (keys.includes('invalidCharacters')) {
        firstError = 'invalidCharacters';
      }

      if (control.errors[firstError] === true) {
        return { key: `errors.${firstError}`, parameters: {} };
      } else {
        return { key: `errors.${firstError}`, parameters: control.errors[firstError] };
      }
    }
  }
}
