import { map, mergeMap, Observable, ReplaySubject, startWith, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export abstract class CacheableService<DTO> {
  private cache = new ReplaySubject<DTO>(1);
  private initialized = '';
  protected constructor(protected httpClient: HttpClient, public url: Observable<string>) {}

  getAll(): Observable<DTO> {
    return this.url.pipe(
      map(url => {
        const lastUrl = this.initialized;
        this.initialized = url;
        return { isDataCached: url === lastUrl, url };
      }),
      mergeMap(({ isDataCached, url }) =>
        isDataCached
          ? this.cache.asObservable().pipe(startWith())
          : this.httpClient.get<DTO>(url).pipe(
              tap(values => {
                this.cache.next(values);
              })
            )
      )
    );
  }
}
