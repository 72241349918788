<ecmo-page [pageTitle]="'documents-request.invoices.title' | translate" [greyBackground]='true'>
  <div class='container' *ngIf='invoiceDates$ | async as invoiceDates'>
    <div class='column' *ngIf='invoiceDates.length > 0; else noInvoice'>
      <div class='row'>
        <span class='label' [innerHTML]="'documents-request.invoices.postal' | translate"></span>
      </div>
      <div class='row separator'></div>
      <div class='row'>
        <span class='label grey' [innerHTML]="'documents-request.invoices.invoice-date-label' | translate"></span>
      </div>
      <div class='column choices'>
        <div class='row choice' [ngClass]='{activated: invoiceDate.validated}' *ngFor='let invoiceDate of invoiceDates'
             (click)='validateIncoive(invoiceDate)'>
          <span>{{invoiceDate.date | date:'MM/YYYY'}}</span>
        </div>
      </div>
      <div class='row or'>
        <div></div>
        <span class='grey'>{{'documents-request.invoices.or' | translate}}</span>
        <div></div>
      </div>
      <div class='row'>
        <mat-checkbox class='inverted' (click)='allInvoicesChecked()' [checked]='allInvoices'>
          {{'documents-request.invoices.all-invoices-label' | translate}}
        </mat-checkbox>
      </div>
      <div class='row'>
        <button [disabled]='!validated && !allInvoices' mat-flat-button color='primary'
                [routerLink]="['../invoices-landing']"
                (click)='sendInvoiceDemand()'>{{ 'documents-request.invoices.confirm' |
          translate }}</button>
      </div>
      <div class='row button'>
        <button mat-stroked-button class='no-border'
                [routerLink]="['/']">{{ 'global.close' | translate }}</button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>

<ng-template #noInvoice>
  <div class='column'>
    <div class='row'>
      {{ 'documents-request.invoices.no-invoice' | translate }}
    </div>
  </div>
</ng-template>
