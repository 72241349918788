<ecmo-text-input [formControl]="control"
                  [matAutocomplete]="autocomplete"
                  [toggleValidationIcon]="toggleValidationIcon"
                  [placeholder] = "placeholder"
                  [label]="label">
  <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="localDisplayFieldMethod">
    <mat-option *ngFor="let option of filteredOption$ | async" [value]="option"
                (onSelectionChange)="selectionChanged.emit($event)">{{localDisplayMethod(option)}}</mat-option>
  </mat-autocomplete>
</ecmo-text-input>
