import { Injectable } from '@angular/core';
import { CacheableService } from '../../../helpers/cacheable.service';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LegalTermsDTO } from './legal-terms.model';
import { AppConfig } from '../../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class LegalTermsService extends CacheableService<LegalTermsDTO> {
  constructor(httpClient: HttpClient, appConfig: AppConfig) {
    super(httpClient, of(`${appConfig.baseWsURL()}/api/partners/legal-notices`));
  }
}
