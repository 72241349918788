export type Contact = ContactDTO & {
  labelKey: string;
};

export type ContactDTO = {
  type: ContactType;
  openingTime?: string;
  phoneNumber?: string;
  emailAddress?: string;
  postalAddress?: string;
  url?: string;
};

export enum ContactType {
  SAV = 'SAV',
  CLAIM = 'CLAIM',
  SINISTER = 'SINISTER',
  ICARE = 'ICARE',
  INSURANCE_TERMINATION = 'INSURANCE_TERMINATION'
}
