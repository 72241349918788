import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContractService } from '../contract.service';
import { Observable, tap } from 'rxjs';
import { ContractDetail, LLD_LABEL } from '../contract.model';

@Component({
  selector: 'ecmo-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractDetailComponent {
  contract$!: Observable<ContractDetail>;
  isLLD = false;

  @Input()
  set contractId(contractId: number | string) {
    this.contract$ = this.contractService
      .getContract(contractId)
      .pipe(tap(contract => (this.isLLD = contract.productType === LLD_LABEL)));
  }

  constructor(private contractService: ContractService) {}
}
