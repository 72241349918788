export type Contract = Pick<ContractDTO, 'brand' | 'model' | 'folderId'> & {
  registration?: string;
  monthlyRentalFee: string;
};

export type ContractDTO = {
  brand: string;
  model: string;
  folderId: string;
  plateNumber?: string;
  monthlyInstalmentAmount: string;
};

export type ContractDetail = Contract &
  Pick<ContractDetailDTO, 'pointOfSale' | 'insurances' | 'documents' | 'creditType' | 'iban'> & {
    productType: string;
    residualValue: string;
    nextPaymentDate: Date;
    creditBeginningDate: Date;
    creditEndingDate: Date;
    numberOfMonths: number;
    remainingMonths: number;
    collectionDay: string;
  };

export type ContractDetailDTO = ContractDTO & {
  product: string;
  residualValueAmount: string;
  pointOfSale: string;
  nextPaymentDate: string;
  creditType: ContractType;
  creditBeginningDate: string;
  creditEndingDate: string;
  insurances: InsuranceDTO[];
  documents: DocumentDTO[];
  levyDayOfMonth: string;
  iban: string;
};

export type InsuranceDTO = {
  label: string;
  monthlyInstalment: string;
};

export type DocumentDTO = {
  documentId: string;
  nature: string;
  type: DocumentDTOType;
  partner: string;
  archivedDate: string;
  referenceDate: string;
};

export enum ContractType {
  CLASSIC = 'CLASSIC',
  LEASE = 'LEASE'
}

export enum DocumentDTOType {
  WELCOME_LETTER = 'WCOURR'
}

export enum DocumentType {
  AMORTIZATION_TABLE = 'AMORTIZATION_TABLE',
  INVOICE = 'INVOICE',
  OVERALL_SITUATION = 'OVERALL_SITUATION'
}

export type Document = {
  title: string;
  url: string;
  type: DocumentType;
};

export const LLD_LABEL = 'Leasing financier avec ER dealer';
