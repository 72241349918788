import { ChangeDetectionStrategy, Component, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ecmo-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgComponent {
  @Input() src = '';
  @Input() title = '';
  @Input() width: number | null = null;
  @Input() height: number | null = null;

  constructor(private sanitizer: DomSanitizer) {}

  replaceSrc(): void {
    this.src = (this.sanitizer.sanitize(SecurityContext.URL, '/assets/img/no-image-found.png') || '').toString();
  }
}
