import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Contact, ContactType } from './contacts.model';
import { IsMobileViewService } from '../../helpers/is-mobile-view.service';
import { ContactsService } from './contacts.service';
import { map } from 'rxjs';

@Component({
  selector: 'ecmo-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ContactsComponent {
  info!: Contact;
  showPopIn = false;
  isMobileView$ = this.isMobileViewService.mobile$;
  savContact$ = this.contactsService
    .getAll()
    .pipe(map(items => items.find(contact => contact.type === ContactType.SAV)));
  claimContact$ = this.contactsService
    .getAll()
    .pipe(map(items => items.find(contact => contact.type === ContactType.CLAIM)));
  sinisterContact$ = this.contactsService
    .getAll()
    .pipe(map(items => items.find(contact => contact.type === ContactType.SINISTER)));
  icareContact$ = this.contactsService
    .getAll()
    .pipe(map(items => items.find(contact => contact.type === ContactType.ICARE)));
  revokeInsurance$ = this.contactsService
    .getAll()
    .pipe(map(items => items.find(contact => contact.type === ContactType.INSURANCE_TERMINATION)));

  constructor(private isMobileViewService: IsMobileViewService, private contactsService: ContactsService) {}

  openPopInInfo(info: Contact): void {
    this.info = info;
    this.showPopIn = true;
  }
}
