<ecmo-page [pageTitle]="'profile.address.title' | translate" [greyBackground]='true'>
  <div class='container profile' *ngIf='user$ | async as user'>
    <div class='column'>
      <div class='row actual'>
        <span class='label'>{{'profile.address.actual-address' | translate}}</span>
        <span class='value'>{{user.address.streetName}} {{user.address.postCode}} {{user.address.city}}</span>
      </div>
      <div class='row separator'></div>
      <div class='row' [formGroup]='addressForm'>
        <div class='column'>
          <p class='row inside'>{{'profile.address.text' | translate}}</p>
          <div class='column inside'>
            <ecmo-address-input formControlName="streetName" [toggleValidationIcon]="false" id="field1"
                               [label]='"profile.address.label.streetName" | translate'
                               [placeholder]='"profile.address.placeholder.streetName" | translate'/>
            <ecmo-address-input formControlName="complement" [toggleValidationIcon]="false" id="field2"
                                [label]='"profile.address.label.complement" | translate'
                                [placeholder]='"profile.address.placeholder.complement" | translate'/>
            <ecmo-zip-code-input formControlName="postCode" [toggleValidationIcon]="false" id="field3"
                                [label]='"profile.address.label.postCode" | translate'
                                [placeholder]='"profile.address.placeholder.postCode" | translate'/>
            <ecmo-text-input formControlName="city" [toggleValidationIcon]="false" id="field4"
                                 [label]='"profile.address.label.city" | translate'
                                 [placeholder]='"profile.address.placeholder.city" | translate'/>
            <ecmo-file-input formControlName="supportingDocument" id="field5"
                             [label]='"profile.address.label.supportingDocument" | translate'
                             [format]='"profile.address.documentFormat" | translate'
                             [placeholder]='"profile.address.placeholder.supportingDocument" | translate'/>
          </div>
        </div>
      </div>
      <div class='row'>
        <button mat-flat-button color='primary' [routerLink]="['../address-landing']"
                (click)='submit()'
                [disabled]='addressForm.invalid'>{{ 'global.send' | translate }}</button>
      </div>
      <div class='row'>
        <button mat-stroked-button class='no-border'
                [routerLink]="['/']">{{ 'global.cancel' | translate }}</button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
