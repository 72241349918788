import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ErrorStateMatcher, MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { combineLatest, map, Observable, ReplaySubject, startWith } from 'rxjs';
import { TextInputComponent } from '../text-input/text-input.component';
import { transformedIncludes } from '../../helpers/object.helper';

export const HTML_SELECTOR = new InjectionToken<string>('HTML_SELECTOR');

@Component({
  selector: 'ecmo-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent<T> extends TextInputComponent implements AfterContentInit, ControlValueAccessor {
  @ViewChild(MatAutocomplete, { static: false }) matAutocomplete!: MatAutocomplete;

  @Input() getValue = (value: T): string => String(value);
  @Output() selectionChanged = new EventEmitter<MatOptionSelectionChange>();
  control: FormControl = this.ngControl?.control as FormControl;

  filteredOption$!: Observable<T[]>;
  optionSet$ = new ReplaySubject<T[]>();
  controlSet$ = new ReplaySubject<FormControl>();

  localDisplayMethod = (value: T): string => String(value);
  localDisplayFieldMethod = this.localDisplayMethod;
  private displayFieldMethodSet = false;
  @Input() set displayMethod(displayMethod: (value: T) => string) {
    this.localDisplayMethod = displayMethod;
    if (!this.displayFieldMethodSet) {
      this.localDisplayFieldMethod = displayMethod;
    }
  }
  @Input() set displayFieldMethod(displayMethod: (value: T) => string) {
    this.localDisplayFieldMethod = displayMethod;
    this.displayFieldMethodSet = true;
  }

  @Input() set options(options: T[] | null) {
    if (options) {
      this.optionSet$.next(options);
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    protected cdr: ChangeDetectorRef,
    @Optional() @Inject(HTML_SELECTOR) protected htmlSelector: string
  ) {
    super(ngControl, defaultErrorStateMatcher, parentForm, parentFormGroup, cdr, htmlSelector || 'ecmo-autocomplete');
  }

  @HostListener('focusout', ['$event.relatedTarget'])
  setControlBlurredEvent(target: MatOption | null): void {
    if (!target) {
      this.matAutocomplete.options.forEach(option => option.deselect());

      const controlValue = this.control?.value;

      const possibleOptions = this.matAutocomplete.options.filter(option => {
        return option.viewValue
          .toLowerCase()
          .includes(
            typeof controlValue === 'string' ? controlValue.toLowerCase() : this.getValue(controlValue).toLowerCase()
          );
      });

      if (controlValue && isNaN(Number(controlValue)) && possibleOptions.length === 1) {
        possibleOptions[0].select();
        this.control?.setValue(possibleOptions[0].value);
      } else {
        this.control?.setValue('');
      }
    }
  }

  ngAfterContentInit(): void {
    if (this.ngControl.control) {
      this.control = this.ngControl.control as FormControl;
      this.required = this.control.hasError('required');

      this.subscriptions.push(
        this.control.statusChanges.subscribe(() => {
          this.cdr.markForCheck();
        })
      );

      this.controlSet$.next(this.control);
    }

    this.filteredOption$ = combineLatest<[FormControl, T[], string | T]>([
      this.controlSet$.asObservable(),
      this.optionSet$.asObservable(),
      this.control.valueChanges.pipe(startWith(''))
    ]).pipe(
      map(([_, options, filterData]) => {
        if (typeof filterData === 'object') {
          return [filterData];
        } else {
          return options.filter(entry => transformedIncludes(entry, this.localDisplayMethod, filterData as string));
        }
      })
    );
  }
}
