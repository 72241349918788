<ecmo-page ecmo-contacts [pageTitle]="'contacts.title' | translate" [greyBackground]='true'>
  <div class='list container'>
    <ng-container *ngIf='savContact$ | async as sav'>
        <ng-container *ngTemplateOutlet='refContact; context:{ contact: {
            labelKey: "sav", mail: sav.emailAddress, phone: sav.phoneNumber, openingTime: sav.openingTime }
          }'></ng-container>
    </ng-container>
    <ng-container *ngIf='savContact$ | async as sav'>
      <ng-container *ngTemplateOutlet='refContact; context:{ contact: {
            labelKey: "disaster", openingTime: sav.openingTime,
            phone: sav.phoneNumber }
          }'></ng-container>
    </ng-container>
    <ng-container *ngIf='icareContact$ | async as icare'>
      <ng-container *ngTemplateOutlet='refContact; context:{ contact: {
            labelKey: "icare", mail: icare.emailAddress, phone: icare.phoneNumber, openingTime: icare.openingTime,
            postalAddress: icare.postalAddress }
          }'></ng-container>
    </ng-container>
    <ng-container *ngIf='revokeInsurance$ | async as revoke'>
      <ng-container *ngTemplateOutlet='refContact; context:{ contact: {
            labelKey: "revoke", url: revoke.url }
          }'></ng-container>
    </ng-container>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>

<div *ngIf='showPopIn' ecmo-contacts-pop-in class='pop-in-container' (click)='showPopIn = false'>
  <div class='pop-in' (click)='$event.stopImmediatePropagation()'
       [ngClass]="{ 'is-mobile': (isMobileView$ | async)?.value === true }">
    <div class='close' (click)='showPopIn = false'><mat-icon>close</mat-icon></div>
    <div class='title'>
      <span class='title-text'>{{ 'contacts.info.pre-title' | translate }}</span>
      <h3 class='title-text'>{{ 'contacts.' + info.labelKey | translate }}</h3>
    </div>
    <div class='content' *ngIf='!info.openingTime' [innerHTML]="'contacts.info.' + info.labelKey | translate">
    </div>
    <div class='content' *ngIf='info.openingTime' [innerHTML]="info.openingTime.replace(' - ', '<br/>')">
    </div>
  </div>
</div>

<ng-template #refContact let-contact="contact">
  <div class='contact'>
    <header>
      <h2>{{ 'contacts.' + contact.labelKey | translate }}</h2>
      <!-- information popin -->
      <mat-icon *ngIf="contact.phone" color='primary' class='info' (click)='openPopInInfo(contact)'>info</mat-icon>
    </header>
    <div class='content'>
      <!-- contactline -->
      <div class='item address' *ngIf='contact.postalAddress'>
        <mat-icon color='primary' [svgIcon]="'ecmo-place-marker'"></mat-icon>
        <span [innerHTML]="contact.postalAddress.replaceAll(' - ', '<br/>')"></span>
      </div>
      <div class='item mail' *ngIf='contact.mail'>
        <a [href]="'mailto:' + contact.mail">
          <mat-icon color='primary'>mail_outline</mat-icon>
          <span>{{ contact.mail }}</span>
        </a>
      </div>
      <div class='item phone' *ngIf='contact.phone'>
        <a [href]="'tel:' + contact.phone" *ngIf='(isMobileView$ | async)?.value === true; else notMobile'>
          <mat-icon color='primary' svgIcon='ecmo-phone'></mat-icon>
          <span>{{ contact.phone }} ({{ 'contacts.phone-cost' | translate }})</span>
        </a>
        <ng-template #notMobile>
          <mat-icon color='primary' svgIcon='ecmo-phone'></mat-icon>
          <span>{{ contact.phone }} ({{ 'contacts.phone-cost' | translate }})</span>
        </ng-template>
      </div>
      <div class='item url' *ngIf='contact.url'>
        <a [href]="contact.url" target='_blank' rel='noopener'>
          <mat-icon color='primary'>open_in_new</mat-icon>
          <span>{{ contact.url }}</span>
        </a>
      </div>
    </div>
  </div>
</ng-template>
