import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../shared/shared.module';
import { loadTranslations, TranslationInitPreload } from '../translate.config';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ViewsModule } from './views/views.module';
import { AuthorizationInterceptor } from '../auth/authorization.interceptor';
import { BYPASS_AUTHENTICATION_ENABLED } from '../auth/auth-config.module';
import { environment } from '../../environments/environment';
import { HttpErrorInterceptor } from '../errors/http-error/http-error.interceptor';

export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    console.error(`[Missing translation for key:  ${params.key}]`);
    return `[Missing translation for key:  ${params.key}]`;
  }
}

@NgModule({
  declarations: [],
  imports: [
    ViewsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      },
      useDefaultLang: true,
      defaultLanguage: 'fr'
    })
  ],
  providers: [
    TranslationInitPreload,
    {
      provide: APP_INITIALIZER,
      useFactory: loadTranslations,
      deps: [TranslationInitPreload],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: BYPASS_AUTHENTICATION_ENABLED,
      useValue: environment.bypassAuthentication
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  exports: [BrowserAnimationsModule, HttpClientModule]
})
export class CoreModule {}
