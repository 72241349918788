<ecmo-page [greyBackground]='true' [pageTitle]="'contract-details.title' | translate">
  <div class='container' *ngIf='contract$ | async as contract'>
    <ecmo-panel [title]="'contract-details.panels.information.title' | translate">
      <div class='content'>
        <div class='double'>
          <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.product'
          | translate, data: contract.productType }"></ng-container>
          <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.folder'
          | translate, data: contract.folderId }"></ng-container>
          <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.registration'
          | translate, data: contract.registration }"></ng-container>
          <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.brand'
          | translate, data: contract.brand }"></ng-container>
          <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.model'
          | translate, data: contract.model }"></ng-container>
          <ng-container *ngIf='!isLLD'>
            <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.residual-value'
            | translate, data: contract.residualValue, type: 'CURRENCY' }"></ng-container>
          </ng-container>
          <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.rental-fee'
          | translate, data: contract.monthlyRentalFee, type: 'CURRENCY' }"></ng-container>
        </div>
        <div class='simple'>
          <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.point-of-sale'
          | translate, data: contract.pointOfSale }"></ng-container>
          <ng-container *ngTemplateOutlet="dataLine; context: { title: 'contract-details.panels.information.next-payment-date'
          | translate, data: contract.nextPaymentDate, type: 'DATE' }"></ng-container>
          <div class='last item'>
            <div class='with-graph item'>
              <span class='title'>{{ 'contract-details.panels.information.graph.title' | translate }}</span>
              <span class='data'>{{ 'contract-details.panels.information.graph.from' | translate }} {{
                contract.creditBeginningDate | date:'MMM YYYY' }} {{ 'contract-details.panels.information.graph.to' |
                translate }} {{ contract.creditEndingDate | date:'MMM YYYY' }} {{
                'contract-details.panels.information.graph.as' | translate }} {{
                contract.remainingMonths }} {{
                'contract-details.panels.information.graph.remaining' |
                  translate }}</span>
            </div>
            <div class='with-graph'>
              <ecmo-graph [ratio]='1 - contract.remainingMonths / contract.numberOfMonths'/>
            </div>
          </div>
        </div>
      </div>
    </ecmo-panel>

    <ecmo-panel [title]="'contract-details.panels.insurances.title' | translate"
                [messageIfEmpty]="'contract-details.panels.insurances.empty' | translate">
      <ng-container *ngIf='contract.insurances?.length'>
        <div class='content'>
          <div class='item' *ngFor='let insurance of contract.insurances'>
            <span>{{insurance.label}}</span>
          </div>
        </div>
      </ng-container>
    </ecmo-panel>
  </div>

  <div class='footer'>
    <a mat-flat-button [routerLink]="['documents-request']"
       color='primary'>{{'contract-details.button.documents' | translate}}</a>
    <a mat-flat-button [routerLink]="['modification-request']"
       color='primary'>{{'contract-details.button.modification' | translate}}</a>
    <ecmo-footer [absolute]='false'></ecmo-footer>
  </div>
</ecmo-page>

<ng-template #dataLine let-title="title" let-data="data" let-type="type">
  <div class='item' *ngIf='data'>
    <span class='title'>{{ title }}</span>
    <span class='data'>
      <ng-container [ngSwitch]='type'>
        <ng-container
          *ngSwitchDefault>{{ data }}</ng-container>
        <ng-container *ngSwitchCase="'DATE'">{{ data | date }}</ng-container>
        <ng-container
          *ngSwitchCase="'CURRENCY'">{{ (0 + data) | currency }}</ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>
