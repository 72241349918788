import { AppRoutingModule } from './app-routing.module';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { registerLocaleData } from '@angular/common';
import LocaleEn from '@angular/common/locales/en';
import LocaleFr from '@angular/common/locales/fr';
import { AuthConfigModule } from './auth/auth-config.module';
import { LayoutModule } from './core/layout/layout.module';
import { HttpErrorComponent } from './errors/http-error/http-error.component';

// to use the local format like Date, Currency...
registerLocaleData(LocaleEn, 'en');
registerLocaleData(LocaleFr, 'fr');

@NgModule({
  declarations: [AppComponent, HttpErrorComponent],
  imports: [SharedModule, CoreModule, AppRoutingModule, AuthConfigModule, LayoutModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
