<ecmo-page [pageTitle]="'summary.title' | translate">
  <ng-container *ngIf='(contracts$ | async) as contracts; else loader'>
    <ng-container *ngIf='activatedFilters$ | async as activatedFilters; else loader'>
      <div class='top'>
        <div class='filter-container' *ngIf='(showFilters$ | async)?.value'>
          <div class='filter-container-actions'>
            <mat-form-field>
              <input matInput [formControl]='filterControl' [placeholder]="'summary.filter-placeholder' | translate"/>
              <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
            <button mat-stroked-button class='filter-button' (click)='showPopIn = true'>
              <mat-icon>filter_list</mat-icon>
            </button>
          </div>

          <div *ngIf='showPopIn' class='pop-in-container' (click)='showPopIn = false'>
            <div class='pop-in' (click)='$event.stopImmediatePropagation()'>
              <div class='close' (click)='showPopIn = false'><mat-icon>close</mat-icon></div>
              <div class='title header'>{{ 'summary.filters.title' | translate }}</div>
              <div class='content'>
                <div class='filter-category'>
                  <div class='title'>{{ 'summary.filters.categories.brand' | translate }}</div>
                  <div class='filters'>
                    <div class='filter' *ngFor='let brand of contracts.availableFilters.brand'
                         (click)="toggleFilter('brand', brand)"
                         [ngClass]='{ active: activatedFilters.brand?.includes(brand) }'>
                      {{brand}}
                    </div>
                  </div>
                </div>
                <div class='filter-category'>
                  <div class='title'>{{ 'summary.filters.categories.model' | translate }}</div>
                  <div class='filters'>
                    <div class='filter' *ngFor='let model of contracts.availableFilters.model'
                         (click)="toggleFilter('model', model)"
                         [ngClass]='{ active: activatedFilters.model?.includes(model) }'>
                      {{model}}
                    </div>
                  </div>
                </div>
                <div class='filter-category'>
                  <div class='title'>{{ 'summary.filters.categories.rental-fee' | translate }} (€)</div>
                  <div class='filters'>
                    <div class='filter' *ngFor='let rentalFee of contracts.availableFilters.monthlyRentalFee'
                         (click)="toggleFilter('monthlyRentalFee', rentalFee)"
                         [ngClass]='{ active: activatedFilters.monthlyRentalFee?.includes(rentalFee) }'>
                      {{'summary.filters.values.rental-fee.' + rentalFee | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='filter-container-chips'>
            <button *ngFor='let brand of activatedFilters.brand' class='filter-chip'
                    (click)="toggleFilter('brand', brand)">
              {{brand}}<mat-icon>close</mat-icon>
            </button>
            <button *ngFor='let model of activatedFilters.model' class='filter-chip'
                    (click)="toggleFilter('model', model)">
              {{model}}<mat-icon>close</mat-icon>
            </button>
            <button *ngFor='let monthlyRentalFee of activatedFilters.monthlyRentalFee' class='filter-chip'
                    (click)="toggleFilter('monthlyRentalFee', monthlyRentalFee)">
              {{'summary.filters.values.rental-fee.' + monthlyRentalFee | translate}}<mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div class='contracts-container'
             [ngClass]="{'two-columns': contracts.content.length > 4}">
          <div class='contract' *ngFor='let contract of contracts.content'>
            <div class='contract-line'>
              <div>
                <div class='title brand'>{{ contract.brand }}</div>
                <div class='model content'>{{ contract.model }}</div>
              </div>
              <div class='align-right'>
                <span class='rental-price'>{{ contract.monthlyRentalFee | currency }}*</span><span>/{{ 'summary.month' |
                translate }}</span>
              </div>
            </div>
            <div class='contract-line'>
              <div>
                <div class='title'>{{ 'summary.labels.id' | translate }}</div>
                <div class='content'>{{ contract.folderId }}</div>
              </div>
              <div class='registration' *ngIf='contract.registration'>
                <div class='title'>{{ 'summary.labels.registration' | translate }}</div>
                <div class='content'>{{ contract.registration }}</div>
              </div>
            </div>
            <a [routerLink]="['contract', contract.folderId]"></a>
          </div>
        </div>
        <div class="paginator">
          <span class='rental-price-note'><strong>*</strong> {{ 'summary.rental-price-note' | translate }}</span>
        </div>
      </div>
      <div class='contracts-footer'>
        <div *ngIf='contracts.numberOfPages !== 1' class='pagination'>
          <button mat-stroked-button color='primary' (click)='askForPage$.next(contracts.pageNumber - 1)'
                  [disabled]='contracts.pageNumber <= 1'>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <span>{{ contracts.pageNumber }} {{ 'summary.of' | translate }} {{ contracts.numberOfPages }}</span>
          <button mat-stroked-button color='primary' (click)='askForPage$.next(contracts.pageNumber + 1)'
                  [disabled]='contracts.pageNumber >= contracts.numberOfPages'>
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <ecmo-footer [absolute]='false'></ecmo-footer>
      </div>
    </ng-container>
  </ng-container>
</ecmo-page>

<ng-template #loader><ecmo-loader></ecmo-loader></ng-template>
