import { Injectable } from '@angular/core';
import { Contract, ContractDetail, ContractDetailDTO, ContractDTO, LLD_LABEL } from './contract.model';
import { urlJoin } from '../../helpers/url.helper';
import { AppConfig } from '../../../app.config';
import { iif, map, mergeMap, Observable, of, tap } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CacheableService } from '../../helpers/cacheable.service';
import { toContract, toContractDetail } from './contract.mapper';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends CacheableService<ContractDTO[]> {
  myContracts = new Map<string | number, ContractDetail>();
  constructor(appConfig: AppConfig, protected httpClient: HttpClient) {
    super(httpClient, of(urlJoin(appConfig.baseWsURL(), `api/contracts`)));
  }

  getContracts(): Observable<Contract[]> {
    return this.getAll().pipe(
      map(contracts => {
        return contracts.map(toContract);
      })
    );
  }

  getContract(contractId: number | string): Observable<ContractDetail> {
    return this.url.pipe(
      mergeMap(url => {
        const cached = this.myContracts.get(contractId);
        return iif(
          () => !!cached,
          of(cached as ContractDetail),
          this.httpClient.get<ContractDetailDTO>(`${url}/${contractId}`).pipe(
            map(contract => toContractDetail(contract)),
            tap(contractDetail => this.myContracts.set(contractId, contractDetail))
          )
        );
      })
    );
  }

  isLLD(contractId: number | string): Observable<{ value: boolean }> {
    return this.getContract(contractId).pipe(map(contract => ({ value: contract.productType === LLD_LABEL })));
  }

  getDocument(contractId: number | string, documentId: number | string): Observable<HttpResponse<Blob>> {
    return this.url.pipe(
      mergeMap(url =>
        this.httpClient.get(`${url}/${contractId}/documents/${documentId}`, {
          observe: 'response',
          responseType: 'blob'
        })
      )
    );
  }

  hasOnlyOneContract(): Observable<boolean> {
    return this.getContracts().pipe(map(contracts => contracts.length === 1));
  }

  changeCollectionDate(contractId: number | string, dayNumber: number | string): Observable<void> {
    return this.url.pipe(
      mergeMap(url => this.httpClient.put<void>(`${url}/${contractId}/levy-day`, { levyDay: Number(dayNumber) }))
    );
  }
}
