import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { mergeMap, Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AUTH_INTERCEPTOR_CONFIG_TOKEN, AuthInterceptorConfig } from './interceptor-config.model';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    @Inject(AUTH_INTERCEPTOR_CONFIG_TOKEN) private config: AuthInterceptorConfig
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.oidcSecurityService.getAccessToken().pipe(
      mergeMap(accessToken => {
        let headers = request.headers;
        if (accessToken && !this.config.exceptionUrls.includes(request.url)) {
          headers = headers.append('Authorization', `Bearer ${accessToken}`);
        }
        const clone = request.clone({ headers });
        return next.handle(clone);
      })
    );
  }
}
