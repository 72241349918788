<ecmo-page [pageTitle]="'modification-request.banking-info.title' | translate" [greyBackground]='true'>
  <div class='container' *ngIf='bankingInfo$ | async as bankingInfo'>
    <div class='column'>
      <div class='column'>
        <div class='row'>
          <span class='label' [innerHTML]="'modification-request.banking-info.actual-text' | translate"></span>
        </div>
        <div class='row info'>
          <span class='title uppercase' [innerHTML]="'modification-request.banking-info.labels.iban' | translate"></span>
          <span class='data'>{{ bankingInfo }}</span>
        </div>
      </div>
      <div class='row separator'></div>
      <div class='row'>
        <span class='label' [innerHTML]="'modification-request.banking-info.explain-change' | translate"></span>
      </div>
      <div class='row address'>
        <mat-icon color='primary' [svgIcon]="'ecmo-place-marker'"></mat-icon>
        <span class='label'>{{ savAddress$ | async }}</span>
      </div>
      <div class='row button'>
        <button mat-stroked-button class='no-border'
                [routerLink]="['/']">{{ 'global.close' | translate }}</button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
