<ecmo-page [greyBackground]='true' [pageTitle]="'documents-request.title' | translate">
  <div class='container' *ngIf='isLLD$ | async as isLLD'>
    <div class='column'>
      <div class='row'>
        <a [href]="'https://espace-contrats.creditclick.fr/login'" target='_blank'>
          {{ 'documents-request.contract-space' | translate }}
        </a>
        <mat-icon svgIcon='ecmo-arrow-from-line-to-right'></mat-icon>
      </div>
      <div class='row'>
        <a (click)='getAccessionAgreement()'>
          {{ 'documents-request.accession-agreement' | translate }}
        </a>
        <mat-icon svgIcon='ecmo-download'></mat-icon>
      </div>
      <div class='row' *ngIf='!isLLD.value'>
        <a [routerLink]="['amortization-schedule']">
          {{ 'documents-request.amortization-schedule.title' | translate }}
        </a>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
      <div class='row' *ngIf='isCompany$ | async'>
        <a [routerLink]="['invoices']">
          {{ 'documents-request.menu.invoices' | translate }}
        </a>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
      <div class='row' *ngIf='!isLLD.value'>
        <a [routerLink]="['situation-letter']">
          {{ 'documents-request.situation-letter.title' | translate }}
        </a>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
