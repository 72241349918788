import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsMobileViewService {
  mobile$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => ({ value: result.matches })));
  constructor(private breakpointObserver: BreakpointObserver) {}
}
