import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { ContractService } from './views/contracts/contract.service';
import { map, Observable } from 'rxjs';

export const hasMoreThanOneFolderGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  return inject(ContractService)
    .getContracts()
    .pipe(map(contracts => contracts.length !== 1 || router.parseUrl(`/contract/${contracts[0].folderId}`)));
};
