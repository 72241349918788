import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urlBuildWithParams, urlJoin } from '../helpers/url.helper';
import { NOT_COLORS_THEME_KEYS, Palette, PaletteColors, THEMES_CONFIG, Theming } from './theming.model';
import { BehaviorSubject, iif, map, mergeMap, Observable, of, takeUntil, tap } from 'rxjs';
import { TakeUntil } from '../helpers/take-until.component';
import { AppConfig } from '../../app.config';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../views/profile/user.service';

@Injectable({
  providedIn: 'root'
})
export class ThemingService extends TakeUntil {
  url = urlJoin(this.appConfig.baseWsURL(), 'partners/themes');
  private EMPTY_THEMING: Theming = {
    themeId: -1,
    primaryColor: '',
    secondaryColor: '',
    colorPalette: {} as Palette
  };
  private currentTheme = new BehaviorSubject<Theming>(this.EMPTY_THEMING);

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userService: UserService
  ) {
    super();

    this.matIconRegistry.addSvgIcon(
      'ecmo-contacts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/ecmo-contacts.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ecmo-edit-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/ecmo-edit-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ecmo-phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/ecmo-phone.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ecmo-sent',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/ecmo-sent.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ecmo-download',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/ecmo-download.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ecmo-arrow-from-line-to-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/ecmo-arrow-from-line-to-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ecmo-place-marker',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/material-icon/svg/ecmo-place-marker.svg')
    );

    this.getCurrentTheming()
      .pipe(takeUntil(this.destroy))
      .subscribe((theming: Theming) => {
        document.documentElement.style.setProperty('--ecmo-primary-color', theming.primaryColor);
        document.documentElement.style.setProperty('--ecmo-secondary-color', theming.secondaryColor);

        if (theming.colorPalette) {
          document.documentElement.style.setProperty('--theme-dark-text-color', theming.colorPalette.darkTextColor);
          document.documentElement.style.setProperty('--theme-light-text-color', theming.colorPalette.lightTextColor);
          Object.keys(theming.colorPalette)
            .filter(key => !(NOT_COLORS_THEME_KEYS as string[]).includes(key))
            .forEach(key => {
              const key1 = `--ecmo-theme-primary-${key}`;
              const value1 = theming.colorPalette[key as keyof PaletteColors];
              const key2 = `--ecmo-theme-primary-contrast-${key}`;
              const value2 = theming.colorPalette.useDarkTextColor.includes(key)
                ? theming.colorPalette.darkTextColor
                : theming.colorPalette.lightTextColor;
              document.documentElement.style.setProperty(key1, value1);
              document.documentElement.style.setProperty(key2, value2);
              if (key === '100') {
                document.documentElement.style.setProperty('--ecmo-theme-primary-border', value1 + '99');
              }
            });
        }
      });
  }

  getThemingJson(forcePartner = ''): Observable<Theming> {
    return iif(() => !forcePartner, this.userService.getPartner(), of(forcePartner)).pipe(
      map(partner => THEMES_CONFIG.get(partner)),
      mergeMap(config =>
        iif(
          () => !forcePartner && this.currentTheme.value !== this.EMPTY_THEMING,
          this.getCurrentTheming(),
          this.httpClient.get<Theming>(urlBuildWithParams(this.url, config?.toParamsMap()))
        )
      ),
      tap(theming => this.setCurrentTheming(theming))
    );
  }

  setCurrentTheming(theming: Theming): void {
    this.currentTheme.next(theming);
  }

  getCurrentTheming(): Observable<Theming> {
    return this.currentTheme.asObservable();
  }

  getCurrentLogoUrl(): Observable<string> {
    return this.getCurrentTheming().pipe(
      map(theme => `${this.appConfig.baseWsURL()}/partners/themes/${theme.themeId}/logo`)
    );
  }
}
