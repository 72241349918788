import { Injectable, InjectionToken } from '@angular/core';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import { AuthInterceptorConfig } from './auth/interceptor-config.model';

export type Config = {
  url: string;
  frontUrl: string;
  ssoBaseUrl: string;
  cookieSecret: string;
  ssoConfig: Partial<OpenIdConfiguration>;
  interceptor: AuthInterceptorConfig;
};

export const VERSION_TOKEN = new InjectionToken<string>('VERSION_TOKEN');

@Injectable()
export abstract class AppConfig {
  abstract baseWsURL: () => string;
  abstract ssoUrl: () => string;
  abstract cookieSecret: () => string;
}
