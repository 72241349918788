import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpClient } from '@angular/common/http';
import { CacheableService } from '../../helpers/cacheable.service';
import { UserDTO } from './user.model';
import { urlJoin } from '../../helpers/url.helper';
import { AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CacheableService<UserDTO> {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private appConfig: AppConfig,
    protected httpClient: HttpClient
  ) {
    super(httpClient, of(urlJoin(appConfig.baseWsURL(), `api/information/me`)));
  }

  getPartner(): Observable<string> {
    return this.oidcSecurityService.getUserData().pipe(map(user => user.partner_name));
  }
}
