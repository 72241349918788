<ecmo-page [pageTitle]="'disconnect.title' | translate" [greyBackground]='true'>
  <div class='container'>
    <div class='column'>
      <div class='row column no-gap'>
        <p class='label'>{{ 'disconnect.you-should-disconnect' | translate }}</p>
      </div>
      <div class='row'>
        <button mat-raised-button color='primary' (click)='logout()'>
          <mat-icon color="secondary" matPrefix>power_settings_new</mat-icon> {{ 'disconnect.disconnect' | translate }}
        </button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
