import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ecmo-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelComponent {
  @Input() title = '';
  @Input() messageIfEmpty = '';
}
