import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from './user.service';
import { map, Observable } from 'rxjs';
import { toUser, User } from './user.model';

@Component({
  selector: 'ecmo-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent {
  user$: Observable<User> = this.userService.getAll().pipe(map(toUser));
  partner$: Observable<string> = this.userService.getPartner();
  constructor(private userService: UserService) {}
}
