import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LegalTermsService } from './legal-terms.service';
import { map } from 'rxjs';

@Component({
  selector: 'ecmo-legal-terms',
  templateUrl: './legal-terms.component.html',
  styleUrls: [
    '../../documents-request/amortization-schedule/amortization-schedule.component.scss',
    './legal-terms.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalTermsComponent {
  legalTerms$ = this.legalTermsService
    .getAll()
    .pipe(
      map(legals => legals.content.replace(/(Siège)|(ORIAS)|(N°)/g, '<br/>$1$2$3').replace(/[^A](\. )/g, '$1<br/>'))
    );

  constructor(private legalTermsService: LegalTermsService) {}
}
