import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ecmo-profile-card-edit',
  templateUrl: './profile-card-edit.component.html',
  styleUrls: ['./profile-card-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileCardEditComponent {
  @Input() url = [''];
  @Input() title = '';
  @Input() content = '';
  @Input() contentTitle = '';
  @Input() editIcon = true;
}
