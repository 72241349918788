import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ecmo-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: [
    '../../core/views/documents-request/amortization-schedule/amortization-schedule.component.scss',
    './http-error.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HttpErrorComponent {
  @Input() status!: string;
}
