import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserService } from '../profile/user.service';
import { DocumentService } from './document.service';
import { ContractService } from '../contracts/contract.service';

@Component({
  selector: 'ecmo-documents-request',
  templateUrl: './documents-request.component.html',
  styleUrls: ['./documents-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsRequestComponent {
  isCompany$ = this.userService.getAll().pipe(map(user => !!user.societyName));
  isLLD$!: Observable<{ value: boolean }>;

  @Input()
  set contractId(contractId: number | string) {
    this.isLLD$ = this.contractService.isLLD(contractId);
  }
  constructor(
    private documentService: DocumentService,
    private userService: UserService,
    private contractService: ContractService
  ) {}

  getAccessionAgreement(): void {
    this.documentService.getAccessionAgreement().subscribe(file => {
      if (file.body) {
        const url = window.URL.createObjectURL(file.body);
        window.open(url, '_blank');
      }
    });
  }
}
