import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Contract } from '../contract.model';
import { ContractService } from '../contract.service';
import { TakeUntil } from '../../../helpers/take-until.component';
import { BehaviorSubject, combineLatest, debounceTime, map, mergeMap, Observable, startWith } from 'rxjs';
import { Filters, Page, RentalFeeFilter } from '../../../helpers/paginator.model';
import { isDefined } from '../../../helpers/object.helper';
import { Paginator } from '../../../helpers/paginator';
import { IsMobileViewService } from '../../../helpers/is-mobile-view.service';

@Component({
  selector: 'ecmo-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryComponent extends TakeUntil {
  filterControl = new FormControl('');
  askForPage$ = new BehaviorSubject<number>(1);
  activatedFilters$ = new BehaviorSubject<Filters<Contract>>({});

  contracts$: Observable<Page<Contract>> = combineLatest([
    this.askForPage$.asObservable(),
    this.activatedFilters$.asObservable(),
    this.filterControl.valueChanges.pipe(debounceTime(400), startWith(''))
  ]).pipe(
    mergeMap(([pageNumber, activatedFilters, filter]) =>
      this.paginator.getPage(pageNumber, filter || '', activatedFilters)
    )
  );

  showFilters$ = combineLatest([
    this.activatedFilters$.asObservable().pipe(startWith({})),
    this.filterControl.valueChanges.pipe(startWith('')),
    this.contracts$.pipe(map(contracts => contracts.numberOfPages !== 1))
  ]).pipe(map(([a, b, c]) => ({ value: Object.keys(a).length > 0 || !!b || c })));

  showPopIn = false;

  rentalFeeFilters: RentalFeeFilter[] = [
    { min: 0, max: 150, labelKey: 'first' },
    { min: 150, max: 300, labelKey: 'second' },
    { min: 300, max: 500, labelKey: 'third' },
    { min: 500, max: 750, labelKey: 'fourth' },
    { min: 750, labelKey: 'fifth' }
  ];

  paginator: Paginator<Contract> = new Paginator(
    this.isMobileViewService.mobile$.pipe(map(isMobileView => (isMobileView.value ? 3 : 6))),
    this.contractService.getContracts(),
    ['brand', 'model', 'folderId', 'registration'],
    ['brand', 'model', 'monthlyRentalFee'],
    this.rentalFeeFilters
  );

  constructor(private contractService: ContractService, private isMobileViewService: IsMobileViewService) {
    super();
  }

  toggleFilter(category: keyof Contract, filterValue: string): void {
    const filters = {} as Filters<Contract>;
    this.fillSelectedFilters(filters, category, this.activatedFilters$.value);
    this.toggleFilterValue(filters, category, filterValue);
    if (filters[category]?.length === 0) {
      delete filters[category];
    }
    this.activatedFilters$.next({
      ...filters
    });
  }

  private toggleFilterValue(filters: Filters<Contract>, category: keyof Contract, filterValue: string): void {
    if (isDefined(filters[category])) {
      let categoryFilters = filters[category] as string[];
      if (categoryFilters.includes(filterValue)) {
        categoryFilters = categoryFilters.filter(v => v !== filterValue);
      } else {
        categoryFilters.push(filterValue);
      }
      filters[category] = categoryFilters;
    } else {
      filters[category] = [filterValue];
    }
  }

  private fillSelectedFilters(
    filters: Filters<Contract>,
    category: keyof Contract,
    activatedFilters: Filters<Contract>
  ): void {
    const { monthlyRentalFee, brand, model } = activatedFilters;
    if (isDefined(monthlyRentalFee) && category === 'monthlyRentalFee') {
      filters[category] = monthlyRentalFee;
    } else if (category !== 'monthlyRentalFee') {
      if (isDefined(brand)) {
        filters['brand'] = brand;
      }
      if (isDefined(model)) {
        filters['model'] = model;
      }
    }
  }
}
