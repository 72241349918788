import { InjectionToken, NgModule } from '@angular/core';
import {
  AbstractSecurityStorage,
  AuthModule,
  DefaultLocalStorageService,
  LogLevel,
  OpenIdConfiguration,
  StsConfigLoader,
  StsConfigStaticLoader
} from 'angular-auth-oidc-client';

export type EcmoConfig = Partial<OpenIdConfiguration> & {
  partner: string;
};
export const AUTH_CONFIG_TOKEN = new InjectionToken<EcmoConfig>('AUTH_CONFIG_TOKEN');
export const BYPASS_AUTHENTICATION_ENABLED = new InjectionToken<boolean>('BYPASS_AUTHENTICATION_ENABLED');

const authConfigFactory = (config: EcmoConfig): StsConfigStaticLoader => {
  return new StsConfigStaticLoader({
    clientId: 'ecm',
    scope: 'openid',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    ignoreNonceAfterRefresh: true,
    triggerRefreshWhenIdTokenExpired: false,
    renewTimeBeforeTokenExpiresInSeconds: 30,
    redirectUrl: window.location.origin + '/',
    postLogoutRedirectUri: window.location.origin,
    logLevel: LogLevel.None,
    customParamsAuthRequest: { partnerId: config.partner },
    customParamsRefreshTokenRequest: { partnerId: config.partner },
    customParamsEndSessionRequest: { partnerId: config.partner },
    customParamsCodeRequest: { partnerId: config.partner },
    ...config
  });
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authConfigFactory,
        deps: [AUTH_CONFIG_TOKEN]
      }
    })
  ],
  exports: [AuthModule],
  providers: [
    {
      provide: AbstractSecurityStorage,
      useClass: DefaultLocalStorageService
    }
  ]
})
export class AuthConfigModule {}
