import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DocumentService } from '../document.service';
import { ContractService } from '../../contracts/contract.service';

export type InvoiceDate = {
  date: Date;
  validated: boolean;
};

@Component({
  selector: 'ecmo-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesComponent {
  invoiceDates$!: Observable<InvoiceDate[]>;
  _invoiceDates!: InvoiceDate[];
  allInvoices = false;
  _contractId!: number | string;
  validated!: InvoiceDate | null;

  @Input()
  set contractId(contractId: number | string) {
    this._contractId = contractId;
    this.invoiceDates$ = this.contractService.getContract(contractId).pipe(
      map(contract => {
        const date = new Date(contract.creditBeginningDate);
        const nextPaymentDate = new Date(contract.nextPaymentDate);
        nextPaymentDate.setMonth(nextPaymentDate.getMonth() - 1);
        this._invoiceDates = [];
        while (date < nextPaymentDate) {
          if (date.getMonth() === 11) {
            date.setFullYear(date.getFullYear() + 1, 0);
          } else {
            date.setMonth(date.getMonth() + 1);
          }
          this._invoiceDates.push({ date: new Date(date), validated: false });
        }
        return this._invoiceDates.reverse();
      })
    );
  }

  constructor(private documentService: DocumentService, private contractService: ContractService) {}

  sendInvoiceDemand(): void {
    this.documentService.sendInvoiceRequest(this._contractId, this.validated?.date, this.allInvoices).subscribe();
  }

  validateIncoive(invoice: InvoiceDate): void {
    this.resetInvoiceDates();
    this.validated = invoice;
    invoice.validated = true;
    this.allInvoices = false;
  }

  allInvoicesChecked(): void {
    if (!this.allInvoices) {
      this.allInvoices = true;
      this.validated = null;
      this.resetInvoiceDates();
    } else {
      this.allInvoices = false;
    }
  }

  private resetInvoiceDates(): void {
    this._invoiceDates
      .filter(invoiceDate => invoiceDate.validated)
      .forEach(invoiceDate => (invoiceDate.validated = false));
  }
}
