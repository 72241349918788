import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ImgComponent } from './img/img.component';
import { PanelComponent } from './panel/panel.component';
import { GraphComponent } from './graph/graph.component';
import { FooterComponent } from './footer/footer.component';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [ImgComponent, PanelComponent, GraphComponent, FooterComponent],
  imports: [CommonModule, RouterLink, NgOptimizedImage],
  exports: [ImgComponent, PanelComponent, GraphComponent, FooterComponent]
})
export class GraphicModule {}
