import { AfterViewInit, Component, ElementRef, HostBinding, Inject } from '@angular/core';
import { ThemingService } from './core/theming/theming.service';
import { TakeUntil } from './core/helpers/take-until.component';
import { combineLatest, filter, map, mergeMap, startWith, tap } from 'rxjs';
import { AppConfig, VERSION_TOKEN } from './app.config';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { BYPASS_AUTHENTICATION_ENABLED } from './auth/auth-config.module';
import { Event, EventType, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ecmo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends TakeUntil implements AfterViewInit {
  @HostBinding('class.show-version')
  showVersion = false;

  authenticatedAndThemeLoaded$ = this.router.events
    .pipe(
      filter((event: Event) => event.type === EventType.GuardsCheckEnd),
      map(
        (event: Event) =>
          event.type === EventType.GuardsCheckEnd &&
          [event.urlAfterRedirects].every(url => ['/privacy-cookies', '/denied'].includes(url))
      )
    )
    .pipe(
      mergeMap(passThrough =>
        this.bypassAuthentication || passThrough
          ? this.themingService.getThemingJson(sessionStorage.getItem('ecm-partner-theme-name') || 'LANDROVER')
          : combineLatest([
              this.oidcSecurityService.checkAuth().pipe(
                tap(loginResponse => {
                  if (!loginResponse.isAuthenticated) {
                    this.oidcSecurityService.authorize();
                  }
                })
              ),
              this.publicEventsService.registerForEvents().pipe(
                filter(
                  notification =>
                    notification.type === EventTypes.NewAuthenticationResult && notification.value.isAuthenticated
                ),
                startWith('')
              )
            ]).pipe(mergeMap(() => this.themingService.getThemingJson()))
      )
    );

  constructor(
    private themingService: ThemingService,
    @Inject(VERSION_TOKEN) protected packageVersion: string,
    @Inject(BYPASS_AUTHENTICATION_ENABLED) private bypassAuthentication: boolean,
    private oidcSecurityService: OidcSecurityService,
    private publicEventsService: PublicEventsService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private appConfig: AppConfig
  ) {
    super();
  }

  toggleVersion(): void {
    this.showVersion = !this.showVersion;
  }

  ngAfterViewInit(): void {
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    s.setAttribute('charset', 'UTF-8');
    s.setAttribute('data-language', 'fr');
    s.setAttribute('data-domain-script', this.appConfig.cookieSecret());
    this.elementRef.nativeElement.appendChild(s);
  }
}
