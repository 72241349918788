import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './page/page.component';
import { MaterialModule } from '../../shared/material/material.module';
import { GraphicModule } from '../../graphic/graphic.module';
import { RouterModule } from '@angular/router';
import { ProfileCardEditComponent } from './profile-card-edit/profile-card-edit.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [PageComponent, ProfileCardEditComponent, LoaderComponent],
  exports: [PageComponent, ProfileCardEditComponent, LoaderComponent],
  imports: [CommonModule, MaterialModule, GraphicModule, RouterModule]
})
export class LayoutModule {}
