<ng-container *ngIf='(isMobileView$ | async) as isMobileView'>
  <header class="sticky top">
    <nav>
      <div class="nav top">
        <div class="partner">
          <a [routerLink]="['/']" class='left'>
            <ecmo-img [src]="(logoUrl$ | async) || ''" [title]="'logo'" />
          </a>
        </div>
        <div class="icon-wrapper" *ngIf='showConnectedIcons'>
          <a [routerLink]="['/contacts']">
            <mat-icon color="primary" svgIcon='ecmo-contacts'></mat-icon>
          </a>
          <a [routerLink]="['/profile']">
            <mat-icon color="primary">person_outline</mat-icon>
          </a>
          <a (click)='logout()'>
            <mat-icon color="primary">power_settings_new</mat-icon>
          </a>
        </div>
      </div>
      <div class="nav bottom" [ngClass]='{ "grey-background": greyBackground, mobile: isMobileView.value }'>
        <div class="title-wrapper">
          <ng-container *ngIf="previousUrl$ | async as previousUrl">
            <a *ngIf="(onRootUrl$ | async) === false" [routerLink]="[previousUrl]"
               (click)='backButtonClicked()'>
              <mat-icon color="primary">arrow_back</mat-icon>
            </a>
          </ng-container>
          <h1>{{ pageTitle }}</h1>
        </div>
      </div>
    </nav>
  </header>
  <main [ngClass]='{ "grey-background": greyBackground, mobile: isMobileView.value }'>
    <div class='main-container'>
      <ng-content></ng-content>
    </div>
  </main>
</ng-container>
