import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Email } from './email.model';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToForm, toValidator } from '../../../custom-mat-form-fields/form-fields.model';
import { EMAIL_REGEX, EMAIL_REGEX_INPUT } from '../../../custom-mat-form-fields/regex.model';
import { map, Observable } from 'rxjs';
import { toUser, User } from '../user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'ecmo-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailComponent {
  user$: Observable<User> = this.userService.getAll().pipe(map(toUser));
  readonly EMAIL_REGEX_INPUT = EMAIL_REGEX_INPUT;

  emailForm = new FormGroup<ToForm<Email>>({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        toValidator((control: AbstractControl) => EMAIL_REGEX.test(control.value), 'global.mailPattern')
      ]
    })
  });

  constructor(private userService: UserService) {}
}
