import { ErrorStateMatcher } from '@angular/material/core';
import {
  AbstractControl,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Subject } from 'rxjs';

export class AbstractMatFormField {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl,
    public stateChanges: Subject<void>
  ) {}
}

export type ToForm<T> = {
  [key in keyof T]: FormControl<T[key]>;
};

const setError = (control: AbstractControl, errors: ValidationErrors | null): void => {
  control.setErrors(errors !== null && Object.keys(errors).length > 0 ? errors : null);
};

export const addErrors = (control: AbstractControl, error: ValidationErrors | null): void => {
  if (error !== null) {
    const errorsWithErrorKey = { ...control.errors, ...error };
    setError(control, errorsWithErrorKey);
  }
};

export const toValidator = (predicate: (_: AbstractControl) => boolean, errorKey: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return predicate(control) ? null : { [errorKey]: true };
  };
};

export type KeyValue = { key: string; value: string };
