<ecmo-page [pageTitle]="'profile.email.title' | translate" [greyBackground]='true'>
  <div class='container profile'>
    <div class='column' *ngIf='user$ | async as user'>
      <div class='row actual'>
        <span class='label'>{{'profile.email.actual-email' | translate}}</span>
        <span class='value'>{{user.coordinates.emailAddress}}</span>
      </div>
      <div class='row separator'></div>
      <div class='row' [formGroup]='emailForm'>
        <div class='column'>
          <p class='row inside'>{{'profile.email.text' | translate}}</p>
          <div class='row inside'>
            <ecmo-text-input formControlName='email'
                             [placeholder]="'profile.email.new-email-placeholder' | translate"
                             [ecmoControlRegex]="EMAIL_REGEX_INPUT"
                             [label]="'profile.email.new-email' | translate"
                             [toggleValidationIcon]="false"
                             autocomplete='email'/>
          </div>
        </div>
      </div>
      <div class='row'>
        <button mat-flat-button color='primary' [routerLink]="['../email-landing']"
                [disabled]='emailForm.invalid'>{{ 'global.send' | translate }}</button>
      </div>
      <div class='row'>
        <button mat-stroked-button class='no-border'
                [routerLink]="['/']">{{ 'global.cancel' | translate }}</button>
      </div>
    </div>
  </div>

  <ecmo-footer></ecmo-footer>
</ecmo-page>
