import { inject, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { SummaryComponent } from './core/views/contracts/summary/summary.component';
import { ContactsComponent } from './core/views/contacts/contacts.component';
import { ProfileComponent } from './core/views/profile/profile.component';
import { ContractDetailComponent } from './core/views/contracts/contract-detail/contract-detail.component';
import { ModificationRequestComponent } from './core/views/modification-request/modification-request.component';
import { EmailComponent } from './core/views/profile/email/email.component';
import { RequestLandingComponent } from './core/views/request-landing/request-landing.component';
import { environment } from '../environments/environment';
import { CollectionDateComponent } from './core/views/modification-request/collection-date/collection-date.component';
import { hasMoreThanOneFolderGuard } from './core/has-more-than-one-folder.guard';
import { authGuard } from './auth/auth.guard';
import { AddressComponent } from './core/views/profile/address/address.component';
import { BankingInfoComponent } from './core/views/modification-request/banking-info/banking-info.component';
import { DocumentsRequestComponent } from './core/views/documents-request/documents-request.component';
import { AmortizationScheduleComponent } from './core/views/documents-request/amortization-schedule/amortization-schedule.component';
import { SituationLetterComponent } from './core/views/documents-request/situation-letter/situation-letter.component';
import { InvoicesComponent } from './core/views/documents-request/invoices/invoices.component';
import { LegalTermsComponent } from './core/views/legals/legal-terms/legal-terms.component';
import { CookiesComponent } from './core/views/legals/cookies/cookies.component';
import { HttpErrorComponent } from './errors/http-error/http-error.component';
import { LogoutComponent } from './core/views/logout/logout.component';
import { CookiesOnlyComponent } from './core/views/legals/cookies/cookies-only/cookies-only.component';
import { AccessDeniedComponent } from './core/views/access-denied/access-denied.component';

const children: Routes = [
  {
    path: '',
    component: SummaryComponent,
    canActivate: [authGuard, hasMoreThanOneFolderGuard],
    runGuardsAndResolvers: 'always'
  },
  { path: 'http-error/:status', component: HttpErrorComponent },
  { path: 'contacts', component: ContactsComponent, canActivate: [authGuard] },
  { path: 'legal-terms', component: LegalTermsComponent, canActivate: [authGuard] },
  { path: 'cookies', component: CookiesComponent, canActivate: [authGuard] },
  {
    path: 'contract/:contractId',
    canActivate: [authGuard],
    children: [
      { path: '', component: ContractDetailComponent },
      {
        path: 'modification-request',
        children: [
          { path: '', component: ModificationRequestComponent },
          {
            path: 'collection-date',
            component: CollectionDateComponent
          },
          {
            path: 'collection-date-landing',
            component: RequestLandingComponent,
            data: {
              delay: true,
              subTextKey: 'request-landing.collection-date',
              backUrl: ['..'],
              pageTitleKey: 'modification-request.collection-date.title'
            }
          },
          {
            path: 'banking-info',
            component: BankingInfoComponent
          }
        ]
      },
      {
        path: 'documents-request',
        children: [
          { path: '', component: DocumentsRequestComponent },
          { path: 'amortization-schedule', component: AmortizationScheduleComponent },
          {
            path: 'amortization-schedule-landing',
            component: RequestLandingComponent,
            data: { delay: true, backUrl: ['..'], pageTitleKey: 'documents-request.amortization-schedule.title' }
          },
          { path: 'invoices', component: InvoicesComponent },
          {
            path: 'invoices-landing',
            component: RequestLandingComponent,
            data: { delay: true, backUrl: ['..'], pageTitleKey: 'documents-request.invoices.title' }
          },
          { path: 'situation-letter', component: SituationLetterComponent },
          {
            path: 'situation-letter-landing',
            component: RequestLandingComponent,
            data: { delay: true, backUrl: ['..'], pageTitleKey: 'documents-request.situation-letter.title' }
          }
        ]
      }
    ]
  },
  {
    path: 'profile',
    canActivate: [authGuard],
    children: [
      { path: '', component: ProfileComponent },
      { path: 'email', component: EmailComponent },
      {
        path: 'email-landing',
        component: RequestLandingComponent,
        data: {
          delay: false,
          subTextKey: 'request-landing.email',
          backUrl: ['/'],
          pageTitleKey: 'profile.email.title'
        }
      },
      { path: 'address', component: AddressComponent },
      {
        path: 'address-landing',
        component: RequestLandingComponent,
        data: {
          delay: false,
          subTextKey: 'request-landing.address',
          backUrl: ['/'],
          pageTitleKey: 'profile.address.title'
        }
      }
    ]
  }
];

const getRoutes = (rootUrl: string): Routes => {
  return [
    { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
    { path: 'denied', component: AccessDeniedComponent, pathMatch: 'full' },
    {
      path: rootUrl,
      children
    },
    { path: 'contract/:contractId', redirectTo: `/${rootUrl}/contract/:contractId`, pathMatch: 'full' },
    { path: 'profile', redirectTo: `/${rootUrl}/profile`, pathMatch: 'full' },
    { path: 'contacts', redirectTo: `/${rootUrl}/contacts`, pathMatch: 'full' },
    { path: 'legal-terms', redirectTo: `/${rootUrl}/legal-terms`, pathMatch: 'full' },
    { path: 'cookies', redirectTo: `/${rootUrl}/cookies`, pathMatch: 'full' },
    { path: 'privacy-cookies', component: CookiesOnlyComponent, pathMatch: 'full' },
    { path: 'http-error/:status', redirectTo: `/${rootUrl}/http-error/:status`, pathMatch: 'full' },
    { path: '**', redirectTo: `/${rootUrl}`, pathMatch: 'full' }
  ];
};

@NgModule({
  imports: [
    RouterModule.forRoot(getRoutes(''), {
      useHash: true,
      enableTracing: !environment.production,
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
    const router = inject(Router);
    const rootUrl = sessionStorage.getItem('ecm-partner-name');
    router.config = getRoutes(rootUrl || '');
  }
}
