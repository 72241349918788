<div class='profile-card'>
  <div class='content holder'>
    <div class='content title'>
      <span>{{ title }}</span><span *ngIf='!editIcon'>*</span>
    </div>
    <div class='content detail'>
      <span *ngIf='contentTitle'>{{ contentTitle }} </span><span [innerHTML]='content'></span>
    </div>
  </div>
  <mat-icon *ngIf='editIcon' svgIcon='ecmo-edit-icon'></mat-icon>
  <ng-container *ngIf='url[0]'>
    <a [routerLink]='url' *ngIf="!url[0].startsWith('http')"></a>
    <a [href]='url' *ngIf="url[0].startsWith('http')" target='_blank'></a>
  </ng-container>
</div>
