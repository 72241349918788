import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DocumentService } from '../document.service';

@Component({
  selector: 'ecmo-situation-letter',
  templateUrl: './situation-letter.component.html',
  styleUrls: ['../amortization-schedule/amortization-schedule.component.scss', './situation-letter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SituationLetterComponent {
  @Input() contractId!: number | string;

  constructor(private documentService: DocumentService) {}

  sendSituationLetterDemand(): void {
    this.documentService.sendSituationLetterRequest(this.contractId).subscribe();
  }
}
